<template>
  <section>
    <Breadcrumb currentPageName="سبد خرید شما"></Breadcrumb>
    <Spinner v-if="loading"> </Spinner>
    <div v-else>
      <!-- basket flow -->
      <div v-if="products.length !== 0" class="row">
        <ul class="basket-flow">
          <li>
            <img src="/template/images/shoping-icon-top.svg" alt="shoping" />
            <p>سبد خرید</p>
          </li>
          <li class="line active"></li>
          <li class="line"></li>
          <li>
            <img src="/template/images/address-icon.svg" alt="address" />
            <p>اطلاعات ارسال</p>
          </li>
          <li class="line"></li>
          <li class="line"></li>
          <li>
            <img src="/template/images/cart-shoping.svg" alt="cart" />
            <p>ثبت نهایی</p>
          </li>
        </ul>
      </div>
      <!-- basket flow -->

      <!-- basket details -->
      <div v-if="products.length !== 0" class="row m-0">
        <div class="custom-table-container col-lg-9">
          <!-- basket items -->
          <table class="custom-table">
            <thead>
              <tr>
                <th>تصویر</th>
                <th>کالا</th>
                <th>ناشر</th>
                <th>تعداد</th>
                <th>نوبت چاپ</th>
                <th>قیمت واحد</th>
                <th>حذف</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in products" :key="product[index]">
                <td>
                  <router-link
                    tag="a"
                    value="مشاهده‌ی جزئیات محصول"
                    type="button"
                    :to="'/product/' + product.slug"
                  >
                  
                    <img
                      width="50px"
                      class="product-img"
                      :src="product.image"
                    
                      alt="img-product"
                    />
                  </router-link>
                </td>
                <td>
                  <router-link
                    tag="a"
                    value="مشاهده‌ی جزئیات محصول"
                    type="button"
                    :to="'/product/' + product.slug"
                  >
                    {{ product.name }}
                  </router-link>
                </td>
                <td>
                  <p class="mb-0">{{ product.brand }}</p>
                </td>
                <td style="position: relative">
                  <!-- update loading -->
                  <div
                    v-if="getLoader(product.productId)"
                    class="counter-loader"
                  >
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <!-- update loading -->
                  <!-- counter -->
                  <div class="d-flex flex-column align-items-center">
                    <span
                      v-if="
                        Number(product.quantity) > Number(product.max_limit)
                      "
                      style="color: red; font-size: 12px; margin-top: 5px"
                    >
                      درخواست بیش از موجودی
                      <br />
                      تعداد درخواستی : {{ product.quantity }} موجودی :
                      {{ product.max_limit }}
                    </span>
                    <div class="apply-counter d-flex" @click="updateProductBasket(product)">
                      <counter
                        :key="index"
                        :min="Number(product.min_limit)"
                        :max="Number(product.max_limit)"
                        :value="product.quantity ? Number(product.quantity) : 1"
                        @input="product.quantity = Number($event)"
                      ></counter>
<!--                      <button @click="updateProductBasket(product)">-->
<!--                        <i class="fa fa-edit"></i>-->
<!--                        ثبت-->
<!--                      </button>-->
                    </div>
                  </div>
                  <!-- counter -->
                </td>
                <td>
                  {{ product.publish }}
                </td>
                <td>
                  <!-- special price -->
                  <div
                    v-if="product.special_price && product.special_price !== 0"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="old-price">
                        {{ new Intl.NumberFormat().format(product.price) }}
                        ریال
                      </p>
                      <p class="discount-percent mr-2" style="width: 50px">
                        {{
                          new Intl.NumberFormat().format(
                            (
                              ((Number(product.price) -
                                Number(product.special_price)) /
                                Number(product.price)) *
                              100
                            ).toFixed(0)
                          ) + "%"
                        }}
                      </p>
                    </div>
                    <p class="new-price">
                      {{
                        new Intl.NumberFormat().format(product.special_price)
                      }}
                      ریال
                    </p>
                  </div>
                  <!-- special price -->

                  <!-- price -->
                  <p v-else class="new-price">
                    {{ new Intl.NumberFormat().format(product.price) }}
                    ریال
                  </p>
                  <!-- price -->
                </td>
                <td>
                  <i
                    class="fa fa-remove"
                    style="cursor: pointer"
                    @click="removeFromBasket(product)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- basket items -->
        </div>
        <!-- basket final info -->
        <div class="col-lg-3 p-3">
          <table class="invoice-table">
            <tbody>
              <tr>
                <td>قیمت نهایی</td>
                <td>{{ new Intl.NumberFormat().format(price.total) }} ریال</td>
              </tr>
              <!-- <tr>
                <td>میزان تخفیف</td>
                <td>{{ new Intl.NumberFormat().format(discount.res) }}%</td>
              </tr> -->
              <tr>
                <td>
                  <button
                    class="info-btn ml-3 mr-3"
                    style="width: 140px"
                    @click="$router.push('/search')"
                  >
                    ادامه‌ی سفارش
                  </button>
                </td>
                <td>
                  <button
                    class="submit-btn mr-3 ml-3"
                    style="width: 110px"
                    @click="registerOrder"
                  >
                    تکمیل خرید
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- basket final info -->
      </div>
      <!-- basket details -->

      <!-- empty basket-->
      <div v-else class="row empty-basket mb-3">
        <div class="col-xl-12" style="text-align: center">
          <img src="/template/images/empty-basket.svg" alt="" />
          <p>شما هیچ کالایی در سبد خرید خود ندارید.</p>
          <button class="info-btn ml-3 mr-3" @click="$router.push('/search')">
            ادامه‌ی سفارش
          </button>
        </div>
      </div>
      <!-- empty basket -->
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";
import counter from "@/components/counter.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "Shopping-Cart",
  components: { Breadcrumb, counter, Spinner },
  data() {
    return {
      loading: false,
      active: true,
      loaders: [],
      products: []
    };
  },
  computed: {
    // products() {
    //   return this.$store.getters.getBasket;
    // },
    price() {
      let total = 0;

      this.products.forEach(item => {
        total +=
          item.special_price && item.special_price != 0
            ? Number(item.special_price) * Number(item.quantity)
            : Number(item.price) * Number(item.quantity);
      });

      return { total };
    },
    discount() {
      let total = 0;

      this.products.forEach(
        item => (total += Number(item.price) * Number(item.quantity))
      );

      let special_prices = 0;

      this.products.forEach(
        item =>
          (special_prices +=
            item.special_price && item.special_price != 0
              ? Number(item.special_price) * Number(item.quantity)
              : 0)
      );

      let res = (
        ((Number(total) - Number(special_prices)) / Number(total)) *
        100
      ).toFixed(0);
      return { res };
    }
  },
  created() {
    //enable loader
    this.loading = true;

    this.$store.dispatch("getBasketReq").then(() => {
      this.products = this.$store.getters.getBasket;
      //disable loader
      this.loading = false;
    });
  },
  beforeRouteUpdate() {
    //enable loader
    this.loading = true;

    this.$store.dispatch("getBasketReq").then(() => {
      this.products = this.$store.getters.getBasket;
      //disable loader
      this.loading = false;
    });
  },
  methods: {
    getLoader(id) {
      let res = this.loaders.find(obj => obj == id);
      return res ? true : false;
    },
    singlePrice(special_price, price) {
      return special_price > 100
        ? Number(price) - Number(price)
        : (
            Number(price) -
            (Number(price) * Number(special_price)) / 100
          ).toFixed(0);
    },
    removeFromBasket(product) {
      this.showProfileQuickAccess = false;

      this.$store
        .dispatch("removeFromBasket", {
          product: product
        })
        .then(() => {
          this.products = this.$store.getters.getBasket;
        });

      //is token exist remove from db
      if (localStorage.getItem("token")) {
        this.$store
          .dispatch("removeFromBasketReq", {
            product: product
          })
          .then(res => {
            if (!res) {
              this.$swal
                .fire({
                  position: "center",
                  icon: "warning",
                  title: "خطا در حذف محصول",
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  //failed then add to basket again
                  this.$store.dispatch("addToBasket", {
                    product: product
                  });
                });
            }
          });
      } else {
        //failed then add to basket again
        this.$store.dispatch("addToBasket", {
          product: product
        });
      }
      // });
    },
    registerOrder() {
      let res = this.getError();
      if (res) {
        this.$swal.fire({
          position: "center",
          icon: "error",
          title: "لطفا کالا های ناموجود را حذف کنید",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      //enable loader
      this.loading = true;

      let products = this.products;
      this.$store
        .dispatch("reqRegisterOrder", {
          products: products
        })
        .then(res => {
          if (res.status === 200) {
            this.$router.push(res.path);
          } else if (res.status === 401) {
            this.$swal
              .fire({
                position: "center",
                icon: "warning",
                title: "لطفا وارد حساب کاربری خود شوید !",
                showConfirmButton: false,
                timer: 1500
              })
              .then(() => {
                this.$router.push({ name: "auth" });
                return;
              });
          } else if (
            res.status === 400 &&
            res.data.status == "address_not_exist"
          ) {
            this.$router.push({ name: "cartAddress" });
          } else if (res.status === 400 && res.data.status == "out_stock") {
            //update basket
            // res.data.products.forEach(() => {
            //   // this.$store
            //   //   .dispatch("updateBasket", {
            //   //     product: item,
            //   //   });
            //     // .then((basket) => {
            //     //   this.products = basket;
            //     // });
            // });
            this.$store.dispatch("getBasketReq").then(() => {
              this.products = this.$store.getters.getBasket;
              this.loading = false;
            });
          }
        });

      //request to get profile to check access
      // this.$store.dispatch("getProfile").then((result) => {
      //   if (result === 401) {
      //     //show unauthorized user
      //     this.$swal
      //       .fire({
      //         position: "center",
      //         icon: "warning",
      //         title: "لطفا وارد حساب کاربری خود شوید !",
      //         showConfirmButton: false,
      //         timer: 1500,
      //       })
      //       .then(() => {
      //         this.$router.push({ name: "auth" });
      //         return;
      //       });
      //     //disable loader
      //     this.loading = false;
      //   } else if (!result.accept) {
      //     this.$swal.fire({
      //       position: "center",
      //       icon: "warning",
      //       title: "حساب کاربری شما تایید نشده است",
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     //disable loader
      //     this.loading = false;
      //   } else if (result.accept) {
      //     //check address and register order
      //     this.$store.dispatch("getAddresses").then((result) => {
      //       if (result.length === 0) {
      //         this.$router.push({ name: "cartAddress" });

      //         //disable loader
      //         this.loading = false;
      //       } else {
      //         let products = this.products;
      //         this.$store
      //           .dispatch("reqRegisterOrder", {
      //             products: products,
      //           })
      //           .then((path) => {
      //             this.$router.push(path);

      //             //disable loader
      //             this.loading = false;
      //           });
      //       }
      //     });
      //   }
      // });
    },
    updateProductBasket(product) {
      let vm = this;
      // setTimeout(function () {

      //enable loader
      this.loaders.push(product.productId);

      // let selected = {
      //   image: product.image,
      //   productId: product.productId,
      //   productTitle: product.productTitle,
      //   price: product.price,
      //   min_limit: product.min_limit,
      //   max_limit: product.max_limit,
      //   quantity: product.quantity,
      //   publish: product.publish.publish,
      //   options: product.options,
      // };

      vm.$store
        .dispatch("updateProductBasketReq", {
          product: product
        })
        .then(res => {
          if (res) {
            //update basket
            vm.$store.dispatch("getBasketReq");
            // vm.$store.dispatch("getBasketReq").then((items) => {
            //   this.products = items;
            // });
            //disable loader
            this.loaders = this.loaders.filter(
              obj => obj !== product.productId
            );
          } else {
            //disable loader
            this.loaders = this.loaders.filter(
              obj => obj !== product.productId
            );

            //loading all page again
            this.loading = true;
            vm.$swal
              .fire({
                position: "center",
                icon: "warning",
                title: "خطا در ویرایش کردن محصول",
                showConfirmButton: false,
                timer: 1500
              })
              .then(() => {
                //update basket & get products again
                vm.$store.dispatch("getBasketReq").then(items => {
                  this.products = items;
                });

                this.loading = false;
              });
          }
        });
      // }, 1000);
    },
    getError() {
      for (let i = 0; i < this.products.length; i++) {
        if (
          Number(this.products[i].quantity) > Number(this.products[i].max_limit)
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
};
</script>
<style scoped>
.custom-table-container {
  padding: 1rem;
}
.custom-table {
  border-radius: 10px;
}
.custom-table thead th {
  font-size: 12px;
}
.custom-table tbody {
  background-color: #ffffff;
}

/* customize loader */
.counter-loader {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
}
.apply-counter button {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: black;
  color: #fff;
  border: none;
  height: 35px;
  padding: 0 5px;
  margin: auto;
}
.apply-counter .num-counter {
  margin: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* customize loader */
/* basket flow */
.basket-flow {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 2rem 0;
}
.basket-flow li:not(.line) {
  text-align: center;
  margin: 0 5px;
}
.basket-flow li img {
  width: 50px;
  height: 50px;
}
.basket-flow li.line {
  width: 50px;
  height: 5px;
  background-color: gray;
}
.basket-flow li.line.active {
  background-color: #ff4476;
}

@media screen and (max-width: 600px) {
  .basket-flow li {
    font-size: 11px;
  }
  .basket-flow li img {
    width: 20px;
    height: 20px;
  }
  .basket-flow li.line {
    width: 20px;
    height: 3px;
  }
}
/* basket flow */
.shopping-product {
  border: 1px solid #1c403e8f;
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .shopping-product {
    margin-left: 0 !important;
  }
  .card-price {
    margin-top: 1rem;
  }
}

/* invoice table */
.invoice-table {
  margin: auto;
  background-color: #ffffff;
  border-radius: 10px;
}
.invoice-table td {
  padding-top: 10%;
  text-align: center;
}
.invoice-table tr > td:first-child {
  margin: auto;
}
</style>
