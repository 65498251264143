import axios from "axios";
import { env } from "../../../enums";

export default {
  state: {},
  getters: {
    getDailyProduct(state) {
      return state.productInfo;
    },
    getFutureProduct(state) {
      return state.futureProducts;
    }
  },
  mutations: {},
  actions: {
    getDailyProductServer() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/product/daily").then(result => {
          if (result.status === 200) {
            resolve(result.data.items);
          }
          reject({});
        });
      });
    },
    getFutureProducts() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/product/future").then(result => {
          if (result.status === 200) {
            resolve(result.data.item);
          }
          reject([]);
        });
      });
    },
    getAccessoryProducts(context, { id }) {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/product/accessory/" + id).then(result => {
          if (result.status === 200) {
            resolve(result.data.item);
          }
          reject([]);
        });
      });
    },
    getProduct(context, { slug }) {
      return new Promise(function(resolve) {
        axios.get(env.base_url + `/product/${slug}`).then(result => {
          if (result.status === 200) {
            resolve(result.data);
          }
        });
      });
    },
    likeAction(context, { productId }) {
      return new Promise(function(resolve, reject) {
        axios
          .post(
            env.base_url + "/account/" + productId + "/like",
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            if (result.status === 200) {
              resolve({ success: true });
            } else {
              reject({ success: false });
            }
          });
      });
    },
    checkLikeStatus(context, { productId }) {
      return new Promise(function(resolve) {
        axios
          .post(
            env.base_url + "/account/" + productId + "/like/status",
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            if (result.status === 200) {
              return resolve(result.data.status);
            }
          });
      });
    },
    remindAction(context, { productId }) {
      return new Promise(function(resolve) {
        axios
          .get(env.base_url + "/product/alert/" + productId, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            if (result.status === 200) {
              resolve(result.data);
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              //unauthorized => do action
              resolve(false);
            }
          });
      });
    },
    saveComment(context, { productId, title, description }) {
      return new Promise(function(resolve) {
        axios
          .post(
            env.base_url + "/product/" + productId + "/comment",
            {
              title,
              description
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
          .then(result => {
            if (result.status === 200) {
              return resolve(true);
            }
            return resolve(false);
          });
      });
    },
    getProductsPerCategory() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/product-by-category").then(result => {
          if (result.status === 200) {
            resolve(result.data.items);
          }
          reject({});
        });
      });
    },
    getProductImages(context, { main_code }) {
      return new Promise(function(resolve, reject) {
        axios
          .get(env.image_server_url + "/product/images/" + main_code)
          .then(result => {
            if (result.status === 200) {
              resolve(result.data);
            }
            reject({});
          });
      });
    },
    getBoxProducts() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/modules/product/box").then(result => {
          if (result.status === 200) {
            resolve(result.data.items);
          }
          reject({});
        });
      });
    }
  }
};
