<template>
  <section id="section-footer">
    <footer class="text-center">
      <hr class="footer-hr" />
      <div class="row d-none d-md-flex">
        <div class="col-3 col-sm-3 high-footer">
          <b>ضمانت اصالت</b>
        </div>
        <div class="col-3 col-sm-3 high-footer">
          <b>ضمانت بازگشت کالا</b>
        </div>
        <div class="col-3 col-sm-3 high-footer">
          <b>ارسال سریع </b>
        </div>
        <div class="col-3 col-sm-3 high-footer">
          <b>پرداخت آنلاین</b>
        </div>
      </div>
      <hr class="footer-hr d-none d-md-flex" />
      <div class="custom-container d-flex flex-column align-items-center">
        <div class="w-100 d-flex justify-content-around flex-wrap">
          <!-- logo -->
          <router-link to="/" class="flex-1 d-flex">
            <img
              class="m-auto"
              src="../../../public/img/logo.png"
              alt="logo"
              width="100px"
            />
          </router-link>

          <!-- logo  -->

          <!-- enemad -->
          <a
            class="flex-1 d-flex"
            referrerpolicy="origin"
            target="_blank"
            href="https://trustseal.enamad.ir/?id=142502&amp;Code=ADWOVX25077EMhPSVPln"
          >
            <!-- <img
              referrerpolicy="origin"
              src="https://Trustseal.eNamad.ir/logo.aspx?id=142502&amp;Code=ADWOVX25077EMhPSVPln"
              alt=""
              width="150px"
              style="cursor: pointer"
              id="ADWOVX25077EMhPSVPln"
          /> -->
            <img
              class="m-auto"
              src="../../../public/template/images/enemad.png"
              alt="logo"
              width="150px"
            />
          </a>
          <!-- enemad  -->

          <!-- services -->
          <ul class="flex-1">
            <li>
              <b>با پاکت </b>
            </li>
            <li>
              <router-link to="/about">درباره‌ی ما</router-link>
            </li>
            <li>
              <router-link to="/contact-us">تماس با ما</router-link>
            </li>
            <li>
              <router-link to="/faq">سؤال‌های متداول</router-link>
            </li>
            <li>
              <router-link to="/terms-and-condition">
                قوانین و مقررات
              </router-link>
            </li>
          </ul>
          <!-- services -->

          <!-- about us -->
          <ul class="flex-1">
            <li>
              <b>لینک‌های سریع </b>
            </li>
            <li>
              <router-link to="/">خانه</router-link>
            </li>
            <li>
              <router-link to="/search">جست‌وجوی پیشرفته</router-link>
            </li>
            <li>
              <router-link to="/blog">بلاگ</router-link>
            </li>
          </ul>
          <!-- about us  -->

          <!-- quick help-->
          <!-- <ul class="flex-1">
            <li>
              <b>راهنما </b>
            </li>
            <li>
              <router-link to="/faq">سؤال‌های متداول</router-link>
            </li>
          </ul> -->
          <!-- quick help-->

          <!-- shop by category -->
          <!-- <ul class="flex-1">
            <li>
              <b> دسته بندی ها </b>
            </li>
            <li>
              <router-link to="/">نشریات</router-link>
            </li>
            <li>
              <router-link to="/">کتاب کودک و نوجوان</router-link>
            </li>
            <li>
              <router-link to="/"> کتاب غیرفارسی</router-link>
            </li>
            <li>
              <router-link to="/terms-and-condition"
                >قوانین و مقررات
              </router-link>
            </li>
          </ul> -->
          <!-- shop by category -->

          <!-- newsletter -->
          <subscribe-newsletter-component></subscribe-newsletter-component>
          <!-- newsletter -->
        </div>
      </div>
      <hr class="footer-hr m-0" />
      <!-- copyright -->
      <div class="d-flex bottom" style="font-size: 14px">
        <!-- <p class="m-auto">
          <a href="https://rabinet.net/" class="copy_right">
            © کلیه حقوق برای مجموعه پاکت محفوظ می باشد.
          </a>
        </p> -->
        <p class="m-auto">
          طراحی و توسعه‌ی سایت توسط
          <a
            href="https://rabinet.net/"
            class="copy_right"
            style="color: #1c403e !important; font-weight: bold"
          >
            رابینت
          </a>
        </p>
      </div>
      <!-- copyright -->
    </footer>
  </section>
</template>

<script>
import subscribeNewsletterComponent from "../../components/footer/subscribeNewsletter.component.vue";

export default {
  name: "footer_",
  components: { subscribeNewsletterComponent },
  data() {
    return {
      information: [],
      email: "",
      newsletterMessage: "",
      messageColor: "color:red",
      loading: false
    };
  }
};
</script>

<style scoped>
.copy_right {
  color: white !important;
}
</style>
