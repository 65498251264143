<template>
  <section class="about">
    <breadcrumb current-page-name="درباره‌ی ما"></breadcrumb>
    <div class="wrapper">
      <div class="col-xl-8 col-md-10 col-10 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
          <!-- درباره‌ی ما -->
        </h5>
        <div class="mt-5">
          <p v-html="body"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";

export default {
  name: "about",
  components: { Breadcrumb },
  data() {
    return {
      title: "",
      body: ""
    };
  },
  created() {
    //get details from backend
    this.$store.dispatch("getDetails", { slug: "about" }).then(result => {
      if (result) {
        this.title = result.title;
        this.body = result.body;
      }
    });
  }
};
</script>
<style scoped>
.about p {
  line-height: 2.5;
  text-align: justify;
}
.about p > br {
  margin-bottom: 1rem;
}
</style>
