<template>
  <div class="m-5">
    <h4 class="text-right mb-3">محبوب‌ترین‌ها</h4>
    <VueSlickCarousel v-bind="settings">
      <ProductItem
        v-for="(product, index) in products"
        :key="product[index]"
        :product="product"
      ></ProductItem>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";
export default {
  name: "favorite",
  props: ["products"],
  data: function() {
    return {
      loading: true,
      settings: {
        dots: true,
        infinite: false,
        slidesToShow: 5,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    /*
    this.$store.dispatch("getFavoriteSlide").then((result) => {
      this.products = result;
      this.loading = false;
    });
    */
  },
  components: {
    VueSlickCarousel,
    ProductItem
  }
};
</script>
