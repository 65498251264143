<template>
  <section>
    <Breadcrumb
      current-page-name="آدرس‌های من"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>

    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>

        <div class="col-lg-8 customer-panel-box">
          <div class="custom-table-container">
            <Spinner v-if="loading"></Spinner>

            <table v-else class="custom-table">
              <thead>
                <tr class="h">
                  <th>نام و نام خانوادگی</th>
                  <th>کد ملی</th>
                  <th>آدرس</th>
                  <th>کد پستی</th>
                  <th>شماره همراه</th>
                  <th>ویرایش</th>
                  <th>حذف</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in addresses" :key="item[index]">
                  <td>
                    {{ item.fullname }}
                  </td>
                  <td>
                    {{ item.melicode }}
                  </td>
                  <td>{{ item.address }}</td>
                  <td>{{ item.postalcode }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <router-link
                      tag="i"
                      :to="'/profile/address/edit/' + item.id"
                      class="m-auto fa fa-pencil"
                      type="button"
                    ></router-link>
                  </td>
                  <td>
                    <i
                      class="delete-icon m-auto fa fa-remove"
                      @click="deleteAddress(item.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <router-link
            tag="input"
            to="/profile/address/add"
            class="submit-btn mt-3"
            type="button"
            value="افزودن آدرس جدید"
          ></router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";

export default {
  name: "profile-edit",
  components: { SideMenu, Breadcrumb, Spinner },
  data() {
    return {
      loading: true,
      addresses: []
    };
  },
  methods: {
    deleteAddress(id) {
      this.loading = true;

      this.$store.dispatch("deleteAddress", { id }).then(result => {
        if (result.success) {
          this.$store.dispatch("getAddresses").then(addresses => {
            this.addresses = addresses;
          });
          this.$swal
            .fire({
              position: "center",
              icon: "success",
              title: "حذف آدرس انجام شد !",
              showConfirmButton: false,
              timer: 1500
            })
            .then(() => {
              // this.loading = false;
              this.getAddress();
            });
        } else {
          this.$swal
            .fire({
              position: "center",
              icon: "error",
              title: "مشکلی در حذف آدرس رخ داده است !",
              showConfirmButton: false,
              timer: 1500
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    getAddress() {
      this.$store.dispatch("getAddresses").then(addresses => {
        this.addresses = addresses;
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getAddress();
  }
};
</script>
<style scoped></style>
