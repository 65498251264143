<template>
  <div>
    <div
            class="custom-modal-backdrop"
            id="custom-backdrop"
            onclick="console.log('close');"
            style="display: none"
    ></div>
    <div
            class="modal fade"
            id="quickview_modal"
            tabindex="-1"
            data-backdrop="false"
            aria-labelledby="quickview_modal"
            aria-modal="true"
            role="dialog"
    >
      <div
              :key="Math.random()"
              class="modal-dialog modal-dialog-product"
              role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              مشاهده‌ی سریع محصول
            </h5>
            <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click="closeModal()"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <Spinner v-if="loading"></Spinner>

            <div v-else>
              <div
                      class="d-flex flex-wrap align-items-center justify-content-around"
              >
                <div class="col-custom">
                  <!-- gallery -->
                  <div class="image-container d-flex">
                    <img
                            class="main-img"
                            :src="
                          mainImage && mainImage.length !== 0
                            ? mainImage
                            : '/template/images/no-image.png'
                        "
                            @error="mainImage = '/template/images/no-image.png'"
                            alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <!-- favorite -->
                    <FavoriteAction
                            class="add-to-wishlist mt-3"
                            :productId="product.id"
                    ></FavoriteAction>
                    <!-- favorite -->
                  </div>

                  <!-- gallery -->
                </div>
                <div class="col-custom mt-3">
                  <!-- details -->
                  <div class="details">
                    <h5 style="max-width: 500px">
                      {{ product.name }}
                    </h5>
                    <br />
                    <div class="book_info">
                      <!-- <label v-if="product.get_brand">
                        ناشر:
                        <router-link
                          :to="{
                            name: 'search',
                            query: {
                              brand: JSON.stringify([
                                {
                                  id: product.get_brand.id,
                                  name: product.get_brand.name
                                }
                              ])
                            }
                          }"
                        >
                          {{ product.get_brand ? product.get_brand.name : "" }}
                        </router-link>
                      </label> -->
                      <label v-if="product.author">
                        نویسنده:
                        <router-link
                                :to="{
                              name: 'search',
                              query: {
                                author: JSON.stringify([
                                  {
                                    id: product.author_id,
                                    name: product.author
                                  }
                                ])
                              }
                            }"
                        >
                          {{ product.author ? product.author : "" }}
                        </router-link>
                      </label>
                      <label v-if="product.translator">
                        مترجم:
                        <router-link
                                :to="{
                              name: 'search',
                              query: {
                                translator: JSON.stringify([
                                  {
                                    id: product.translator_id,
                                    name: product.translator
                                  }
                                ])
                              }
                            }"
                        >
                          {{ product.translator ? product.translator : "" }}
                        </router-link>
                      </label>

                      <label v-if="publish.main_code"
                      >کداصلی: {{ publish.main_code }}</label
                      >
                      <label v-if="product.brand_name"
                      >ناشر:
                        <router-link
                                :to="{
                              name: 'search',
                              query: {
                                brand: JSON.stringify([
                                  {
                                    id: product.brand_id,
                                    name: product.brand_name
                                  }
                                ])
                              }
                            }"
                        >
                          {{ product.brand_name ? product.brand_name : "" }}
                        </router-link>
                      </label>
                      <label v-if="product.main_topic"
                      >موضوع: {{ product.main_topic }}</label
                      >
                      <label v-if="publish.cover_type"
                      >نوع جلد: {{ publish.cover_type }}</label
                      >
                      <label v-if="publish.book_size"
                      >قطع: {{ publish.book_size }}</label
                      >
                      <label v-if="publish.publish_year"
                      >سال چاپ: {{ publish.publish_year }}</label
                      >
                      <label v-if="publish.weight">
                        وزن: {{ publish.weight }}</label
                      >
                      <label v-if="product.page_count"
                      >صفحه: {{ product.page_count }}</label
                      >
                      <label v-if="publish.isbn"
                      >شابک: {{ publish.isbn }}</label
                      >
                      <label v-if="publish.created_at"
                      >تاریخ ورود به انبار:
                        {{
                        publish.created_at | moment("jDD-jMM-jYYYY")
                        }}</label
                      >
                    </div>
                    <label
                            v-if="
                          Number(publish.quantity) > 0 &&
                            Number(publish.quantity) < x
                        "
                    >
                      وضعیت موجودی:
                      <span style="color: darkred"
                      >تنها {{ Number(publish.quantity) }} عدد باقی
                          مانده</span
                      >
                    </label>
                    <label v-else-if="Number(publish.quantity) <= 0">
                      وضعیت موجودی:
                      <span style="color: #e3572e">ناموجود</span>
                    </label>
                    <label v-else-if="Number(publish.quantity) >= x">
                      وضعیت موجودی:
                      <span style="color: green">موجود</span>
                    </label>

                    <div class="input-group align-items-center">
                      <!-- publish -->
                      <label>نوبت چاپ :</label>
                      <select
                              class="f  orm-control select-mr mr-2"
                              v-model="publish"
                              ref="publish"
                              @change="calculatePrice()"
                      >
                        <option
                                v-for="(item, index) in product.dynamicAttributes"
                                :key="'publish' + index"
                                :value="item"
                        >
                          <span v-if="item.publish">{{ item.publish }}</span>
                        </option>
                      </select>
                      <!-- publish -->

                      <!-- counter -->
                      <label v-if="Number(publish.quantity) > 0">تعداد :</label>
                      <counter
                              ref="counter"
                              v-if="Number(publish.quantity) > 0"
                              :min="product.min_limit ? product.min_limit : 1"
                              :max="
                            product.max_limit &&
                            Number(product.max_limit) < Number(publish.quantity)
                              ? product.max_limit
                              : publish.quantity
                          "
                              :value="product.min_limit || 1"
                              @input="count = $event"
                      ></counter>
                      <!-- counter -->
                    </div>
                    <br />

                    <!-- special price -->
                    <div
                            v-if="
                          publish.special_price && publish.special_price !== 0
                        "
                    >
                      <div class="d-flex">
                        <p class="old-price">
                          {{
                          new Intl.NumberFormat().format(
                          publish.back_cover_price
                          )
                          }}
                          ریال
                      </p>
                        <p class="discount-percent mr-2">
                          <!-- {{
                          publish.special_price > 100
                            ? new Intl.NumberFormat().format(publish.special_price)
                            : publish.special_price + "%"
                        }} -->
                          {{
                          new Intl.NumberFormat().format(
                          (
                          ((Number(publish.back_cover_price) -
                          Number(publish.special_price)) /
                          Number(publish.back_cover_price)) *
                          100
                          ).toFixed(0)
                          ) + "%"
                          }}
                        </p>
                      </div>
                      <p class="new-price">
                        <!-- {{
                        publish.special_price > 100
                          ? new Intl.NumberFormat().format(
                              Number(publish.back_cover_price) -
                                Number(publish.special_price)
                            )
                          : new Intl.NumberFormat().format(
                              (
                                Number(publish.back_cover_price) -
                                (Number(publish.back_cover_price) *
                                  Number(publish.special_price)) /
                                  100
                              ).toFixed(0)
                            )
                      }} -->
                        {{
                        new Intl.NumberFormat().format(publish.special_price)
                        }}
                        ریال
                      </p>
                    </div>
                    <!-- special price -->

                    <!-- price -->
                    <p v-else class="new-price" v-show="publish">
                      {{
                      new Intl.NumberFormat().format(publish.back_cover_price)
                      }}
                      ریال
                    </p>
                    <!-- price -->

                    <div>
                      <Spinner v-if="basketLoading" :circle="true"></Spinner>

                      <input
                              v-else-if="publish.quantity > 0"
                              @click="addToCard()"
                              class="submit-btn btn-margin"
                              type="button"
                              style="width: 160px"
                              value="افزودن به سبد خرید"
                      />

                      <!-- request order -->
                      <input
                              v-if="Number(publish.quantity) === 0"
                              @click="registerOrder()"
                              class="info-btn"
                              type="button"
                              value="سفارش کالا"
                      />
                      <!-- request order  -->

                      <!-- <input
                      v-else
                      @click="registerOrder()"
                      class="info-btn btn-margin"
                      type="button"
                      value="سفارش کالا"
                    /> -->
                    </div>
                    <!-- details -->
                  </div>
                  <!-- details -->
                </div>
              </div>
              <!--
              <ProductContent
                  :product="product"
                  :publish="publish"
              ></ProductContent>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
            class="modal-backdrop fade show"
            id="backdrop"
            onclick="console.log('close');"
            @click="closeModal()"
            style="display: none"
    ></div>
  </div>
</template>

<script>
  import Spinner from "@/components/Spinner.component";
  import FavoriteAction from "@/components/FavoriteAction.component";
  import counter from "@/components/counter.component";

  export default {
    name: "quickview",
    components: { Spinner, FavoriteAction, counter },
    data() {
      return {
        loader: true,
        loading: true,
        basketLoading: false,
        mainImage: "",
        selected: {},
        product: {},
        publish: {
          id: "",
          price: ""
        },
        count: 0,
        price: 0,
        x: 10
      };
    },
    created() {
      this.$root.$refs.quickview = this;
    },
    methods: {
      registerOrder() {
        let selected = {
          productId: this.product.id,
          attrId: this.publish.id,
          quantity: this.count
        };

        //enable loader
        this.loading = true;

        this.$store
                .dispatch("registerOrder", {
                  options: selected
                })
                .then(result => {
                  if (result === 401) {
                    this.$swal
                            .fire({
                              position: "center",
                              icon: "warning",
                              title: "لطفا وارد حساب کاربری خود شوید !",
                              showConfirmButton: false,
                              timer: 1500
                            })
                            .then(() => {
                              this.$router.push({ name: "auth" });
                              return;
                            });
                  } else if (result === 200) {
                    this.$swal.fire({
                      position: "center",
                      icon: "success",
                      title: "سفارش شما با موفقیت ثبت شد",
                      showConfirmButton: false,
                      timer: 1500
                    });
                  } else {
                    this.$swal.fire({
                      position: "center",
                      icon: "error",
                      title: "ثبت درخواست با مشکل مواجه شد",
                      showConfirmButton: false,
                      timer: 1500
                    });
                  }

                  //disable loader
                  this.loading = false;
                });
      },
      closeModal() {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("quickview_modal").style.display = "none";
        document.getElementById("quickview_modal").classList.remove("show");
      },
      setProductInfo(checkproduct) {
        this.loading = true;

        this.product = checkproduct;
        if (checkproduct.attributes) {
          this.product.dynamicAttributes = checkproduct.attributes;
        }
        this.mainImage = this.product.intro_image;
        if (
                this.product.dynamicAttributes &&
                this.product.dynamicAttributes.length > 0
        ) {
          this.publish = this.product.dynamicAttributes[0];
          this.count = this.product.min_limit ? this.product.min_limit : 1;
        }
        this.loading = false;
      },
      calculatePrice() {
        this.count = this.product.min_limit ? this.product.min_limit : 1;
        //reset count
        return (
                Number(this.count) *
                Number(
                        this.publish.special_price && this.publish.special_price !== 0
                                ? this.publish.special_price
                                : this.publish.back_cover_price
                )
        );
      },
      addToCard() {
        if (!this.publish || !this.publish.id || this.count <= 0) {
          this.$refs.publish.focus();
          return;
        }

        //save in localstorage and update basket
        let selected = {
          image: this.product.intro_image,
          productId: this.product.id,
          name: this.product.name,
          price: this.product.price,
          special_price: this.product.special_price ?? 0,
          min_limit: this.product.min_limit,
          max_limit: this.product.max_limit,
          quantity: this.count,
          // publish: this.publish.publish, //pass all publish
          options: this.publish.id,
          publish_quantity: this.product.quantity,
          slug: this.product.slug
        };

        this.$store.dispatch("addToBasket", {
          product: selected
        });
        if (localStorage.getItem("token")) {
          //enable loader
          this.basketLoading = true;

          this.$store
                  .dispatch("addToBasketReq", { product: selected })
                  .then(res => {
                    if (!res) {
                      this.$swal
                              .fire({
                                position: "center",
                                icon: "warning",
                                title: "خطا در اضافه کردن محصول",
                                showConfirmButton: false,
                                timer: 1500
                              })
                              .then(() => {
                                //failed then remove from basket
                                this.$store.dispatch("removeFromBasket", {
                                  product: selected
                                });
                              });
                    }
                    //disable loader
                    this.basketLoading = false;

                    this.closeModal();
                  });
        }
        // this.$store
        //     .dispatch("addToBasket", {
        //       product: selected
        //     })
        //     .then(product => {
        //       //if user is login save in db
        //       if (localStorage.getItem("token")) {
        //         //enable loader
        //         this.basketLoading = true;

        //         this.$store
        //             .dispatch("addToBasketReq", {product: product})
        //             .then(res => {
        //               if (!res) {
        //                 this.$swal
        //                     .fire({
        //                       position: "center",
        //                       icon: "warning",
        //                       title: "خطا در اضافه کردن محصول",
        //                       showConfirmButton: false,
        //                       timer: 1500
        //                     })
        //                     .then(() => {
        //                       //failed then remove from basket
        //                       this.$store.dispatch("removeFromBasket", {
        //                         product: product
        //                       });
        //                     });
        //               }
        //               //disable loader
        //               this.basketLoading = false;
        //             });
        //       }
        //       this.closeModal();
        //     });
      }
    }
  };
</script>

<style scoped>
  .add-to-wishlist {
    border: none !important;
    color: #1c403e !important;
    margin-right: auto;
    margin-left: auto;
    font-size: 13px;
  }

  .details {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: column;
    text-align: right;
    line-height: 2;
    height: 100%;
    min-width: 400px;
    justify-content: space-between;
  }

  .image-container {
    max-height: 500px;
  }

  .image-container ul {
    direction: ltr;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0 !important;
  }

  .image-container ul li {
    padding-right: 5px;
  }

  .image-container ul::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  .image-container ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .image-container ul::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }

  .image-container .main-img {
    max-width: 200px;
    margin: 0 1rem;
  }

  .btn-margin {
    margin: 1rem;
  }

  .image-container ul li img {
    width: 50px;
    margin: 0 auto 0.5rem auto;
    cursor: pointer;
  }

  .old-price {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    color: #9b9b9b;
    text-decoration-line: line-through;
  }

  .select-mr {
    margin-left: 1rem;
    flex: 0.5 1 auto !important;
  }

  .new-price {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: #000000;
  }

  .discount-percent {
    height: 25px;
    width: 45px;
    border-radius: 29px;
    background: rgba(255, 68, 118, 1);
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 550px) {
    .image-container-div {
      width: 100%;
    }

    .image-container {
      flex-flow: column-reverse;
      max-height: 100%;
    }

    .image-container .slides {
      display: flex;
      margin-top: 1rem;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .image-container ul li {
      display: flex;
    }

    .col-custom {
      width: 100%;
    }

    .details {
      min-width: 100%;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog-product {
      max-width: 870px !important;
    }
  }

  .close {
    float: left;
    font-weight: bold;
    margin: unset !important;
    display: inline;
    padding: 10px !important;
  }

  .custom-modal-backdrop {
    position: fixed;
    background-color: black;
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1040;
  }

  #quickview_modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: unset;
  }

  .book_info {
    line-height: 10px;
    font-size: 14px;
  }

  .book_info > * {
    display: block;
  }
</style>
