<style scoped>
.pagination-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  align-items: center;
  margin-bottom: 0 !important;
  display: block !important;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}
</style>

<template>
  <div>
    <Breadcrumb :sectionName="'بلاگ'" :sectionAddr="'/blog'"></Breadcrumb>
    <div class="container">
      <div v-if="loading" align="center" style="margin-top:30px">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else class="row" style="margin-top:30px">
        <div
          v-for="(post, index) in posts"
          class="col-md-3 col-sm-1"
          :key="post[index]"
        >
          <router-link :to="'/post/' + post.slug">
            <div class="card" style="width: 18rem">
              <img
                :src="postImageUrl(post.image)"
                class="post_slide_img"
                :alt="post.title"
              />
              <div class="card-body">
                <b class="post_title">{{ post.title }}</b>
                <p v-show="post.excerpt">{{ post.excerpt }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div v-if="posts" class="pagination-container">
        <pagination
          class="pagination"
          :chunk="5"
          v-model="current_page"
          :records="pages"
          @paginate="getPosts()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "vue-pagination-2";
import Breadcrumb from "@/components/breadcrumb.component";
import { env } from "../../../enums";

export default {
  name: "blog",
  components: {
    Pagination,
    Breadcrumb
  },
  data: function() {
    return {
      loading: true,
      wallet: [],
      posts: null,
      current_page: 1,
      pages: 1
    };
  },
  created() {
    this.$store
      .dispatch("getBlogPost", {
        page: this.current_page
      })
      .then(result => {
        this.posts = result.data;
        this.loading = false;
        this.current_page = result.current_page;
        this.pages = result.last_page;
      });
  },
  methods: {
    getPosts() {
      this.$store
        .dispatch("getBlogPost", {
          page: this.current_page
        })
        .then(result => {
          this.posts = result.data;
          this.loading = false;
          this.current_page = result.current_page;
          this.pages = result.last_page;
        });
    },
    postImageUrl(url) {
      return env.image_url + url;
    }
  }
};
</script>
<style scoped>
.post_slide_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.post_title {
  text-align: right;
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  color: black;
}

@media screen and (max-width: 480px) {
  .post_slide_img {
    height: 150px;
  }
}
</style>
