import axios from "axios";
import { env } from "../../../enums";
export default {
  state: {
    loader: false
  },
  getters: {
    getLoader(state) {
      return state.loader;
    }
  },
  mutations: {
    setLoader(state, loader) {
      state.loader = loader;
    }
  },
  actions: {
    getMenu() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + `/menu`).then(result => {
          if (result.status == 200) {
            resolve(result.data.item);
          }
          reject("");
        });
      });
    }
  }
};
