<template>
  <section class="home">
    <Spinner v-if="loading" :circle="true" class="mt-5 mb-5"></Spinner>
    <div v-else>
      <Slider v-if="slides && slides.length" :items="slides"></Slider>

      <Category
        v-if="homepage.Categories && homepage.Categories.length !== 0"
        :categories="homepage.Categories"
      ></Category>
    </div>

    <!-- amazing products -->
    <AmazingProducts v-if="settings.amazingProducts"></AmazingProducts>
    <!-- amazing products -->

    <!-- kids products -->
    <BoxProducts v-if="settings.boxProducts"></BoxProducts>
    <!-- kids products -->

    <!-- lateset products -->
    <LatestProduct v-if="settings.latestProducts"></LatestProduct>
    <!-- lateset products -->

    <Spinner v-if="loading" :circle="true" class="mt-5 mb-5"></Spinner>
    <div v-else>
      <div v-if="homepage.banner01_url">
        <a :href="homepage.banner01_url">
          <img
            :src="homepage.banner01"
            class="img-responsive"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              position: relative;
            "
          />
        </a>
      </div>

      <FavoriteSlider
        v-if="
          settings.favoriteProducts &&
            homepage.Favorites &&
            homepage.Favorites.length !== 0
        "
        :products="homepage.Favorites"
      ></FavoriteSlider>

      <div v-if="homepage.banner02" class="mt-5">
        <a :href="homepage.banner02_url">
          <img
            :src="homepage.banner02"
            class="img-responsive"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              position: relative;
            "
          />
        </a>
      </div>

      <BestSell
        v-if="
          settings.bestSaleProducts &&
            homepage.BestSales &&
            homepage.BestSales.length !== 0
        "
        :products="homepage.BestSales"
      ></BestSell>

      <SelectedProducts
        v-if="
          settings.selectedProducts &&
            homepage.SelectiveProducts &&
            homepage.SelectiveProducts.length !== 0
        "
        :products="homepage.SelectiveProducts"
      ></SelectedProducts>

      <!--
      <LastPost
        v-if="homepage.lastPosts && homepage.lastPosts.length !== 0"
        :posts="homepage.lastPosts"
      >
      </LastPost> -->
    </div>
  </section>
</template>
<script>
// @ is an alias to /src
import Slider from "@/components/Slider.component";
import Category from "@/components/slider/Category.component";
import BoxProducts from "@/components/slider/BoxProducts.component";
import AmazingProducts from "@/components/slider/AmazingProducts.component";
import FavoriteSlider from "@/components/slider/Favorite.component";
import BestSell from "@/components/slider/BestSell.component";
import LatestProduct from "@/components/slider/latestProduct.component";
// import LastPost from "@/components/slider/LastPost.component";
import SelectedProducts from "@/components/slider/SelectedProducts.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "Home",
  data: function() {
    return {
      slides: [],
      // categoryProducts: [],
      homepage: {},
      loading: false,
      bestSellBanner: {},
      settings: {}
    };
  },
  computed: {},
  mounted() {
    (this.loading = true),
      //get slides
      this.$store.dispatch("getSlides").then(result => {
        this.slides = result;
      });

    //get products per category
    // this.$store.dispatch("getProductsPerCategory").then((result) => {
    //   this.categoryProducts = result;
    // });

    this.$store.dispatch("getHomePage").then(result => {
      this.homepage = result;
      this.settings = result.settings;
      
      // this.newReleasesBanner = {
      //   image: result.banner01,
      //   url: result.banner01_url,
      // };
      // this.bestSellBanner = {
      //   image: result.banner02,
      //   url: result.banner02_url,
      // };
      this.loading = false;
    });
  },
  components: {
    Slider,
    Category,
    FavoriteSlider,
    BestSell,
    // LastPost,
    LatestProduct,
    AmazingProducts,
    BoxProducts,
    SelectedProducts,
    Spinner
  }
};
</script>
<style scoped>

.not-exist-product {
  text-align: center;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>
