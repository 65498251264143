<template>
  <section class="orders">
    <Breadcrumb
      current-page-name="تاریخچه‌ی سفارش‌ها"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>
    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>

        <div class="col-lg-8 customer-panel-box">
          <div class="d-flex flex-column">
            <ul class="tablist">
              <li
                @click="
                  current = true;
                  delivery = false;
                  lost = false;
                  accepted = false;
                "
                class=""
              >
                در حال بررسی (
                <span class="num-in-process">
                  {{ currentOrders.items.length }} </span
                >)
              </li>
              <li
                @click="
                  current = false;
                  delivery = false;
                  lost = false;
                  accepted = true;
                "
                class=""
              >
                درحال پردازش و آماده‌سازی (<span class="num-done">{{
                  acceptedOrders.items.length
                }}</span
                >)
              </li>
              <li
                @click="
                  current = false;
                  delivery = true;
                  lost = false;
                  accepted = false;
                "
                class=""
              >
                ارسال شده (<span class="">{{
                  deliveryOrders.items.length
                }}</span
                >)
              </li>
              <li
                @click="
                  current = false;
                  delivery = false;
                  lost = true;
                  accepted = false;
                "
                class=""
              >
                لغو شده (<span class="">{{ lostOrders.items.length }}</span
                >)
              </li>
            </ul>

            <div v-if="current">
              <Spinner v-if="currentOrders.loading"></Spinner>
              <div v-else class="custom-table-container">
                <table
                  v-if="currentOrders.items.length != 0"
                  class="custom-table"
                >
                  <tbody>
                    <tr
                      v-for="(order, index) in currentOrders.items"
                      :key="order[index]"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ "PAKAT_" + order.id }}
                      </td>
                      <td>
                        {{ order.created_at | moment("jYY-jMM-jDD HH:mm") }}
                      </td>
                      <td>
                        {{ new Intl.NumberFormat().format(order.total_price) }}
                        ریال
                      </td>
                      <td>در حال بررسی</td>
                      <td>
                        <router-link
                          tag="input"
                          class="info-btn"
                          type="button"
                          value="جزئیات سفارش"
                          :to="'/profile/orders/order/' + order.id"
                        ></router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  v-else
                  class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی وجود ندارد
                </p>
              </div>

              <!-- pagination -->
              <div
                v-if="currentOrders.items.length != 0"
                class="pagination-container"
              >
                <ul class="pagination mr-2">
                  <!-- first page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: currentOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(1, 'current')"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <!-- first page -->

                  <!-- prev -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: currentOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Previous"
                      @click="goToPrev('current')"
                    >
                      <span aria-hidden="true">&lsaquo;</span>
                    </a>
                  </li>
                  <!-- prev -->

                  <!-- pages -->
                  <li
                    class="page-item"
                    v-if="currentOrders.current_page - 1 > 0"
                  >
                    <a
                      class="page-link"
                      @click="
                        goToPage(currentOrders.current_page - 1, 'current')
                      "
                      >{{ currentOrders.current_page - 1 }}</a
                    >
                  </li>

                  <li class="page-item active">
                    <a
                      class="page-link"
                      @click="goToPage(currentOrders.current_page, 'current')"
                      >{{ currentOrders.current_page }}</a
                    >
                  </li>

                  <li
                    class="page-item"
                    v-if="currentOrders.current_page + 1 < currentOrders.pages"
                  >
                    <a
                      class="page-link"
                      @click="
                        goToPage(currentOrders.current_page + 1, 'current')
                      "
                      >{{ currentOrders.current_page + 1 }}</a
                    >
                  </li>
                  <!-- pages -->

                  <!-- next -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled:
                        currentOrders.current_page === currentOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToNext('current')"
                    >
                      <span aria-hidden="true">&rsaquo;</span>
                    </a>
                  </li>
                  <!-- next -->

                  <!-- last page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled:
                        currentOrders.current_page === currentOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(currentOrders.pages, 'current')"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                  <!-- last page -->
                </ul>
                <label class="mt-1"
                  >تعداد کل صفحات: {{ currentOrders.pages }}</label
                >
              </div>
              <!-- pagination -->
            </div>

            <div v-if="accepted" class="custom-table-container">
              <Spinner v-if="acceptedOrders.loading"></Spinner>
              <div v-else class="custom-table-container">
                <table
                  v-if="acceptedOrders.items.length != 0"
                  class="custom-table"
                >
                  <tbody>
                    <tr
                      v-for="(order, index) in acceptedOrders.items"
                      :key="order[index]"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ "PAKAT_" + order.id }}
                      </td>
                      <td>
                        {{ order.created_at | moment("jYY-jMM-jDD HH:mm") }}
                      </td>
                      <td>
                        {{ new Intl.NumberFormat().format(order.total_price) }}
                        ریال
                      </td>
                      <td>درحال پردازش و آماده‌سازی</td>
                      <td>
                        <router-link
                          tag="input"
                          class="info-btn"
                          type="button"
                          value="جزئیات سفارش"
                          :to="'/profile/orders/order/' + order.id"
                        ></router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  v-else
                  class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی وجود ندارد
                </p>
              </div>
              <!-- pagination -->
              <div
                v-if="acceptedOrders.items.length != 0"
                class="pagination-container"
              >
                <ul class="pagination mr-2">
                  <!-- first page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: acceptedOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(1, 'accepted')"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <!-- first page -->

                  <!-- prev -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: acceptedOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Previous"
                      @click="goToPrev('accepted')"
                    >
                      <span aria-hidden="true">&lsaquo;</span>
                    </a>
                  </li>
                  <!-- prev -->

                  <!-- pages -->
                  <li
                    class="page-item"
                    v-if="acceptedOrders.current_page - 1 > 0"
                  >
                    <a
                      class="page-link"
                      @click="
                        goToPage(acceptedOrders.current_page - 1, 'accepted')
                      "
                      >{{ acceptedOrders.current_page - 1 }}</a
                    >
                  </li>

                  <li class="page-item active">
                    <a
                      class="page-link"
                      @click="goToPage(acceptedOrders.current_page, 'accepted')"
                      >{{ acceptedOrders.current_page }}</a
                    >
                  </li>

                  <li
                    class="page-item"
                    v-if="
                      acceptedOrders.current_page + 1 < acceptedOrders.pages
                    "
                  >
                    <a
                      class="page-link"
                      @click="
                        goToPage(acceptedOrders.current_page + 1, 'accepted')
                      "
                      >{{ acceptedOrders.current_page + 1 }}</a
                    >
                  </li>
                  <!-- pages -->

                  <!-- next -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled:
                        acceptedOrders.current_page === acceptedOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToNext('accepted')"
                    >
                      <span aria-hidden="true">&rsaquo;</span>
                    </a>
                  </li>
                  <!-- next -->

                  <!-- last page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled:
                        acceptedOrders.current_page === acceptedOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(acceptedOrders.pages, 'accepted')"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                  <!-- last page -->
                </ul>
                <label class="mt-1"
                  >تعداد کل صفحات: {{ acceptedOrders.pages }}</label
                >
              </div>
              <!-- pagination -->
            </div>

            <div v-if="delivery" class="custom-table-container">
              <Spinner v-if="deliveryOrders.loading"></Spinner>

              <div v-else class="custom-table-container">
                <table
                  v-if="deliveryOrders.items.length != 0"
                  class="custom-table"
                >
                  <tbody>
                    <tr
                      v-for="(order, index) in deliveryOrders.items"
                      :key="order[index]"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ "PAKAT_" + order.id }}
                      </td>
                      <td>
                        {{ order.created_at | moment("jYY-jMM-jDD HH:mm") }}
                      </td>
                      <td>
                        {{ new Intl.NumberFormat().format(order.total_price) }}
                        ریال
                      </td>
                      <td>ارسال شده</td>
                      <td>
                        <router-link
                          tag="input"
                          class="info-btn"
                          type="button"
                          value="جزئیات سفارش"
                          :to="'/profile/orders/order/' + order.id"
                        ></router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  v-else
                  class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی وجود ندارد
                </p>
              </div>

              <!-- pagination -->
              <div
                v-if="deliveryOrders.items.length != 0"
                class="pagination-container"
              >
                <ul class="pagination mr-2">
                  <!-- first page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: deliveryOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(1, 'delivery')"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <!-- first page -->

                  <!-- prev -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: deliveryOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Previous"
                      @click="goToPrev('delivery')"
                    >
                      <span aria-hidden="true">&lsaquo;</span>
                    </a>
                  </li>
                  <!-- prev -->

                  <!-- pages -->
                  <li
                    class="page-item"
                    v-if="deliveryOrders.current_page - 1 > 0"
                  >
                    <a
                      class="page-link"
                      @click="
                        goToPage(deliveryOrders.current_page - 1, 'delivery')
                      "
                      >{{ deliveryOrders.current_page - 1 }}</a
                    >
                  </li>

                  <li class="page-item active">
                    <a
                      class="page-link"
                      @click="goToPage(deliveryOrders.current_page, 'delivery')"
                      >{{ deliveryOrders.current_page }}</a
                    >
                  </li>

                  <li
                    class="page-item"
                    v-if="
                      deliveryOrders.current_page + 1 < deliveryOrders.pages
                    "
                  >
                    <a
                      class="page-link"
                      @click="
                        goToPage(deliveryOrders.current_page + 1, 'delivery')
                      "
                      >{{ deliveryOrders.current_page + 1 }}</a
                    >
                  </li>
                  <!-- pages -->

                  <!-- next -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled:
                        deliveryOrders.current_page === deliveryOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToNext('delivery')"
                    >
                      <span aria-hidden="true">&rsaquo;</span>
                    </a>
                  </li>
                  <!-- next -->

                  <!-- last page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled:
                        deliveryOrders.current_page === deliveryOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(deliveryOrders.pages, 'delivery')"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                  <!-- last page -->
                </ul>
                <label class="mt-1"
                  >تعداد کل صفحات: {{ deliveryOrders.pages }}</label
                >
              </div>
              <!-- pagination -->
            </div>

            <div v-if="lost">
              <Spinner v-if="lostOrders.loading"></Spinner>

              <div v-else class="custom-table-container">
                <table v-if="lostOrders.items.length != 0" class="custom-table">
                  <tbody>
                    <tr
                      v-for="(order, index) in lostOrders.items"
                      :key="order[index]"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ "PAKAT_" + order.id }}
                      </td>
                      <td>
                        {{ order.created_at | moment("jYY-jMM-jDD HH:mm") }}
                      </td>
                      <td>
                        {{ new Intl.NumberFormat().format(order.total_price) }}
                        ریال
                      </td>
                      <td>لغو شد</td>
                      <td>
                        <router-link
                          tag="input"
                          class="info-btn"
                          type="button"
                          value="جزئیات سفارش"
                          :to="'/profile/orders/order/' + order.id"
                        ></router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  v-else
                  class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی وجود ندارد
                </p>
              </div>

              <!-- pagination -->
              <div
                v-if="lostOrders.items.length != 0"
                class="pagination-container"
              >
                <ul class="pagination mr-2">
                  <!-- first page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: lostOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(1, 'lost')"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <!-- first page -->

                  <!-- prev -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: lostOrders.current_page === 1
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Previous"
                      @click="goToPrev('lost')"
                    >
                      <span aria-hidden="true">&lsaquo;</span>
                    </a>
                  </li>
                  <!-- prev -->

                  <!-- pages -->
                  <li class="page-item" v-if="lostOrders.current_page - 1 > 0">
                    <a
                      class="page-link"
                      @click="goToPage(lostOrders.current_page - 1, 'lost')"
                      >{{ lostOrders.current_page - 1 }}</a
                    >
                  </li>

                  <li class="page-item active">
                    <a
                      class="page-link"
                      @click="goToPage(lostOrders.current_page, 'lost')"
                      >{{ lostOrders.current_page }}</a
                    >
                  </li>

                  <li
                    class="page-item"
                    v-if="lostOrders.current_page + 1 < lostOrders.pages"
                  >
                    <a
                      class="page-link"
                      @click="goToPage(lostOrders.current_page + 1, 'lost')"
                      >{{ lostOrders.current_page + 1 }}</a
                    >
                  </li>
                  <!-- pages -->

                  <!-- next -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: lostOrders.current_page === lostOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToNext('lost')"
                    >
                      <span aria-hidden="true">&rsaquo;</span>
                    </a>
                  </li>
                  <!-- next -->

                  <!-- last page -->
                  <li
                    class="page-item"
                    v-bind:class="{
                      disabled: lostOrders.current_page === lostOrders.pages
                    }"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click="goToPage(lostOrders.pages, 'lost')"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                  <!-- last page -->
                </ul>
                <label class="mt-1"
                  >تعداد کل صفحات: {{ lostOrders.pages }}</label
                >
              </div>
              <!-- pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";

export default {
  name: "profile-edit",
  data() {
    return {
      currentOrders: {
        items: [],
        current_page: 1,
        pages: "",
        total: "",
        loading: false
      },
      deliveryOrders: {
        items: [],
        current_page: 1,
        pages: "",
        total: "",
        loading: false
      },
      lostOrders: {
        items: [],
        current_page: 1,
        pages: "",
        total: "",
        loading: false
      },
      acceptedOrders: {
        items: [],
        current_page: 1,
        pages: "",
        total: "",
        loading: false
      },
      delivery: false,
      current: true,
      lost: false,
      accepted: false
      // loading: true,
    };
  },
  components: { SideMenu, Breadcrumb, Spinner },
  created() {
    this.getCurrentOrders();
    this.getDeliveryOrders();
    this.getLostOrders();
    this.getAcceptedOrders();
  },
  methods: {
    getCurrentOrders() {
      this.currentOrders.loading = true;
      this.$store
        .dispatch("getOrders", {
          orderType: "current",
          page: this.currentOrders.current_page
        })
        .then(result => {
          this.currentOrders.items = result.data;
          this.currentOrders.current_page = result.current_page;
          this.currentOrders.pages = result.last_page;
          this.currentOrders.total = result.total;
          this.currentOrders.loading = false;
        });
    },
    getDeliveryOrders() {
      this.deliveryOrders.loading = true;
      this.$store
        .dispatch("getOrders", {
          orderType: "delivery",
          page: this.deliveryOrders.current_page
        })
        .then(result => {
          this.deliveryOrders.items = result.data;
          this.deliveryOrders.current_page = result.current_page;
          this.deliveryOrders.pages = result.last_page;
          this.deliveryOrders.total = result.total;
          this.deliveryOrders.loading = false;
        });
    },
    getLostOrders() {
      this.lostOrders.loading = true;
      this.$store
        .dispatch("getOrders", {
          orderType: "lost",
          page: this.lostOrders.current_page
        })
        .then(result => {
          this.lostOrders.items = result.data;
          this.lostOrders.current_page = result.current_page;
          this.lostOrders.pages = result.last_page;
          this.lostOrders.total = result.total;
          this.lostOrders.loading = false;
        });
    },
    getAcceptedOrders() {
      this.acceptedOrders.loading = true;
      this.$store
        .dispatch("getOrders", {
          orderType: "accepted",
          page: this.acceptedOrders.current_page
        })
        .then(result => {
          this.acceptedOrders.items = result.data;
          this.acceptedOrders.current_page = result.current_page;
          this.acceptedOrders.pages = result.last_page;
          this.acceptedOrders.total = result.total;
          this.acceptedOrders.loading = false;
        });
    },
    goToPrev(type) {
      switch (type) {
        case "current":
          if (this.currentOrders.current_page > 1) {
            this.currentOrders.current_page--;
            this.getCurrentOrders();
          }
          break;
        case "delivery":
          if (this.deliveryOrders.current_page > 1) {
            this.deliveryOrders.current_page--;
            this.getDeliveryOrders();
          }
          break;
        case "lost":
          if (this.lostOrders.current_page > 1) {
            this.lostOrders.current_page--;
            this.getLostOrders();
          }
          break;
        case "accepted":
          if (this.acceptedOrders.current_page > 1) {
            this.acceptedOrders.current_page--;
            this.getAcceptedOrders();
          }
          break;
        default:
        // code block
      }
    },
    goToNext(type) {
      switch (type) {
        case "current":
          if (this.currentOrders.current_page < this.currentOrders.pages) {
            this.currentOrders.current_page++;
            this.getCurrentOrders();
          }
          break;
        case "delivery":
          if (this.deliveryOrders.current_page < this.deliveryOrders.pages) {
            this.deliveryOrders.current_page++;
            this.getDeliveryOrders();
          }
          break;
        case "lost":
          if (this.lostOrders.current_page < this.lostOrders.pages) {
            this.lostOrders.current_page++;
            this.getLostOrders();
          }
          break;
        case "accepted":
          if (this.acceptedOrders.current_page < this.acceptedOrders.pages) {
            this.acceptedOrders.current_page++;
            this.getAcceptedOrders();
          }
          break;
        default:
        // code block
      }
    },
    goToPage(page, type) {
      switch (type) {
        case "current":
          this.currentOrders.current_page = page;
          this.getCurrentOrders();
          break;
        case "delivery":
          this.deliveryOrders.current_page = page;
          this.getDeliveryOrders();
          break;
        case "lost":
          this.lostOrders.current_page = page;
          this.getLostOrders();
          break;
        case "accepted":
          this.acceptedOrders.current_page = page;
          this.getAcceptedOrders();

          break;
        default:
        // code block
      }
    }
  }
};
</script>
<style scoped>
.tablist {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  margin-bottom: 0;
  margin-top: 1rem;
}
.tablist li {
  width: max-content;
  background-color: #424242;
  color: #fff;
  border-left: 1px solid #fff;
  padding: 0.5rem 1rem;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}
.tablist li:first-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.tablist li:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.orders .custom-table-container {
  border-top: 1px solid #e7e7e7;
}
</style>
