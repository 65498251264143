import { render, staticRenderFns } from "./walletInfo.component.vue?vue&type=template&id=b3c07470&scoped=true&"
import script from "./walletInfo.component.vue?vue&type=script&lang=js&"
export * from "./walletInfo.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3c07470",
  null
  
)

export default component.exports