export const env = Object.freeze({
    //  base_url: "http://127.0.0.1:8000/api/v1",
    // base_url: "http://192.168.0.183:8000/api/v1",
    //  base_url: "http://192.168.0.183:8000/api/v1",

    // base_url: "http://127.0.0.1:8000/api/v1",
    //  image_url: "http://127.0.0.1:8000/storage/",
    base_url: "https://server.pakatshop.com/api/v1",
    image_url: "https://server.pakatshop.com/storage/",
    image_server_url: "https://server.cheshmehdis.com/api/v1"

});
