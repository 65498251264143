<template>
  <div>
    <hr />
    <VueSlickCarousel v-bind="settings">
      <router-link
        v-for="(category, index) in categories"
        :key="category[index]"
        tag="a"
        value="دسته بندی"
        class="a-link"
        type="button"
        :to="'/category/' + category.slug"
      >
        <img
          class="img-responsive"
          width="50px"
          :src="category.icon"
          @error="category.icon = '/template/images/no-image.png'"
          alt=""
        />

        <b class="category_title">
          {{ category.name }}
        </b>
      </router-link>
    </VueSlickCarousel>
    <hr />
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "category",
  props: ["categories"],
  data: function() {
    return {
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 7
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    /*
    this.$store.dispatch("getproductcategories").then((result) => {
      this.categories = result;
      this.loading = false;
    });
    */
  },
  components: {
    VueSlickCarousel
  }
};
</script>

<style scoped>
.category_title {
  display: block;
  text-align: center;
  margin: 5px;
  font-size: 14px;
}

.a-link {
  color: #1c403e;
  text-align: center;
}
.a-link:hover {
  color: #1e857f;
}
.slide_style {
  text-align: center;
}
</style>
