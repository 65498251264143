<template>
  <div class="form col-xl-4 col-md-6">
    <h5 class="mb-3">تأیید حساب</h5>

    <!-- show error  -->
    <p
      v-if="$store.getters.getAuthErrors"
      class="text-center"
      style="color: red"
    >
      {{ $store.getters.getAuthErrors }}
    </p>
    <!-- show error  -->

    <Spinner v-if="loading"></Spinner>
    <div class="w-100" v-else>
      <!-- registerd items -->
      <!-- <div v-if="isNew" class="row w-100 mb-3">
        <div class="d-flex align-items-center">
          <label>نام و نام خانوادگی:</label>
          <span
            v-if="fullnameError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >نام و نام خانوادگی الزامی است</span
          >
        </div>
        <input
          v-model="fullname"
          ref="fullname"
          type="text"
          name="fullname"
          class="input mt-0"
          placeholder="نام و نام خانوادگی"
        />
      </div>
      <div v-if="isNew" class="row w-100 mb-3">
        <div class="d-flex align-items-center">
          <label>ایمیل:</label>
          <span
            v-if="emailError"
            class="mr-3 mb-1"
            style="font-size: 12px; color: red"
            >ایمیل الزامی است</span
          >
        </div>
        <input
          v-model="email"
          ref="email"
          class="input mt-0"
          type="email"
          name="email"
          placeholder="پست الکترونیک"
        />
      </div> -->
      <!-- registerd items -->

      <!-- code input -->

      <p class="mt-3 text-center">کد ارسال شده را وارد کنید</p>
      <CodeInput
        class="ltr m-auto"
        :loading="false"
        v-on:complete="onComplete"
        :fields="4"
        type="number"
        v-model="code"
        :fieldWidth="75"
        :fieldHeight="40"
      />
      <div class="">
        <p class="text-center">
          <b v-if="again == false"
            >ارسال مجدد کد تا <span>{{ timer }}</span> ثانیه‌ی دیگر
          </b>
          <a
            v-if="again == true"
            style="cursor: pointer"
            class="mt-3"
            @click="resend()"
          >
            ارسال مجدد
            <i class="fa fa-refresh"></i>
          </a>
        </p>
      </div>
      <!-- code input -->

      <!-- buttons -->
      <div class="group-btns d-flex">
        <input
          @click="checkVerifyCode"
          class="submit-btn w-100 ml-3 mr-3 mb-3"
          type="button"
          value="تایید و ادامه"
        />
        <input
          @click="login"
          class="info-btn w-100 ml-3 mr-3 mb-3"
          type="button"
          value=" بازگشت"
        />
      </div>
      <!-- buttons -->
    </div>
  </div>
</template>
<script>
import CodeInput from "vue-verification-code-input";
import Spinner from "../../components/Spinner.component";

export default {
  name: "verify",
  components: {
    CodeInput,
    Spinner
  },
  data() {
    return {
      timer: "00:00",
      again: false,
      isNew: false,
      code: "",
      // fullname: "",
      // email: "",
      loading: false
    };
  },
  created() {
    //
    this.isNew = this.$store.getters.getIsNew;

    let minute = 1;
    let second = 59;
    if (this.isNew) {
      minute = 4;
    }
    setInterval(() => {
      this.timer = `${minute}:${(second -= 1)}`;
      if (minute > 0 && second == 0) {
        minute -= 1;
        second = 59;
      }
      if ((minute == 0) & (second == 0)) {
        this.again = true;
        minute = 1;
        second = 59;
        if (this.isNew) {
          minute = 4;
        }
      }
    }, 1000);
  },
  computed: {
    // fullnameError() {
    //   return this.fullname.length < 1 ? true : false;
    // },
    // emailError() {
    //   return this.email.length < 1 ? true : false;
    // }
  },
  methods: {
    onComplete(v) {
      this.code = v;
    },
    checkVerifyCode() {
      this.$store.commit("setAuthError", ""); //clean old error

      //send phonenumber and email if is new user
      // if (this.isNew && this.fullnameError) {
      //   this.$refs.fullname.focus();
      //   return;
      // }
      // if (this.isNew && this.emailError) {
      //   this.$refs.email.focus();
      //   return;
      // }

      //enable loader
      this.loading = true;

      if (this.isNew) {
        //register
        this.$store
          .dispatch("register", {
            code: this.code,
            phone: this.$store.getters.getPhone
            // fullname: this.fullname,
            // email: this.email
          })
          .then(res => {
            if (res) {
              //sync basket
              this.$store.dispatch("updateBasketReq").then(res => {
                if (res) {
                  this.$store.dispatch("getBasketReq");
                }
              });

              //disable loader
              this.loading = false;

              //go to befor
              this.$router.push({ name: "ProfileInfo" });
            } else {
              this.$store.commit("setAuthError", "دوباره تلاش کنید");
              //disable loader
              this.loading = false;
            }
          });
      } else {
        //checkVerifyCode
        this.$store
          .dispatch("checkVerifyCode", {
            phone: this.$store.getters.getPhone,
            code: this.code
          })
          .then(token => {
            if (!token) {
              this.code = "";
              //disable loader
              this.loading = false;
            } else if (token) {
              //sync basket
              this.$store.dispatch("updateBasketReq").then(res => {
                if (res) {
                  this.$store.dispatch("getBasketReq");
                }
              });

              //set token
              this.$store.commit("setAuthRoutineLevel", {
                state: "WELCOME",
                token: token
              });

              //disable loader
              this.loading = false;

              //go to befor
              this.$router.push({ name: "ProfileInfo" });
            }
          });
      }
    },
    resend() {
      this.$store.commit("setAuthError", ""); //clean old error

      //enable loader
      this.loading = true;

      //send verify code
      this.$store
        .dispatch("sendVerifyCode", {
          phone: this.$store.getters.getPhone
        })
        .then(() => {
          //disable loader
          this.loading = false;
          this.again = false;
        });
    },
    login() {
      this.$store.commit("setAuthError", ""); //clean old error
      this.$store.commit("setAuthRoutineLevel", { status: "LOGIN" });
    }
  }
};
</script>

<style scoped></style>
