<template>
    <section>
        <Breadcrumb currentPageName="ثبت نهایی"></Breadcrumb>
        <Spinner v-if="loading"></Spinner>
        <div v-else>
            <!-- basket flow -->
            <div class="row">
                <ul class="basket-flow">
                    <li>
                        <img src="/template/images/shoping-icon-top.svg" alt="shoping"/>
                        <p>سبد خرید</p>
                    </li>
                    <li class="line active"></li>
                    <li class="line active"></li>
                    <li>
                        <img src="/template/images/Pin2.svg" alt="address"/>
                        <p>اطلاعات ارسال</p>
                    </li>
                    <li class="line active"></li>
                    <li class="line active"></li>
                    <li>
                        <img src="/template/images/cart2.svg" alt="cart"/>
                        <p>ثبت نهایی</p>
                    </li>
                </ul>
            </div>
            <!-- basket flow -->

            <div class="custom-container">
                <div class="row text-right">
                    <div class="col-xl-6">
                        <!-- addressess -->
                        <div class="section-header">
                            <img src="/template/images/Subtract-pin.svg" alt=""/>
                            آدرس
                            <router-link
                                    tag="a"
                                    style="color: #1c403e !important;font-size: 13px;float:left"
                                    type="button"
                                    :to="'/profile/address/add'"
                            >
                                افزودن آدرس جدید
                            </router-link>
                        </div>
                        <div>
                            <ul
                                    v-for="(item, index) in addresses"
                                    :key="item[index]"
                                    class="d-flex flex-wrap spaceing-ul"
                            >
                                <li style="padding-left: 15px">
                                    <input
                                            v-model="addressId"
                                            name="address"
                                            :value="item.id"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                </li>
                                <li>
                                    {{ item.fullname }}
                                </li>
                                <li>
                                    <p>{{ item.address }}</p>
                                </li>
                                <li>
                                    {{ item.phone }}
                                </li>
                            </ul>

                            <label v-if="errors.address" style="color: red"
                            >انتخاب آدرس ارسال الزامی میباشد !</label
                            >
                        </div>
                        <!-- addressess -->

                        <!-- shippping -->
                        <div class="section-header">
                            <img src="/template/images/Subtract-pin.svg" alt=""/>
                            روش ارسال
                        </div>
                        <div>
                            <ul
                                    v-for="(item, index) in shippings"
                                    :key="item[index]"
                                    class="d-flex flex-wrap"
                            >
                                <li class="ml-2 mr-2">
                                    <input
                                            v-model="shipping"
                                            name="shipping"
                                            :value="item"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                </li>
                                <li class="ml-2 mr-2">
                                    <img style="width: 30px" :src="item.image" alt=""/>
                                </li>
                                <li class="ml-2 mr-2">
                                    {{ item.name }}
                                </li>
                                <li>
                                    {{
                                    item.price && Number(item.price !== 0)
                                    ? new Intl.NumberFormat().format(item.price) + "ریال "
                                    : "رایگان"
                                    }}
                                </li>
                                <li>
                                    <p style="padding-right: 7%; font-size: 13.5px;">
                                        {{ item.description }}
                                    </p>
                                </li>
                            </ul>
                            <label v-if="errors.shipping" style="color: red"
                            >انتخاب روش ارسال الزامی است !</label
                            >
                        </div>


                        <!-- shippping -->

                        <!-- description -->
                        <div class="section-header">
                            <img src="/template/images/Subtract-pin.svg" alt=""/>
                            توضیحات سفارش
                        </div>
                        <div>
              <textarea
                      class="input"
                      v-model="description"
                      placeholder="محل ورود توضیحات سفارش"
              ></textarea>
                        </div>
                        <!-- description -->
                    </div>
                    <div class="col-xl-6">
                        <!-- discount -->
                        <div class="section-header">
                            <img src="/template/images/gift.svg" alt=""/>
                            کد تخفیف
                        </div>
                        <ul class="discount">
                            <li>
                                <input
                                        class="input"
                                        type="text"
                                        name=""
                                        :disabled="discountInput"
                                        v-model="discountCode"
                                        :placeholder="discountInputText"
                                />
                            </li>
                            <li>
                                <button
                                        @click="applyDiscount()"
                                        class="submit-btn"
                                        type="button"
                                        :disabled="discountInput"
                                >
                                    ثبت کد تخفیف
                                </button>
                                <label v-if="errors.discount" style="color: red">{{
                                    errors.discount
                                    }}</label>
                            </li>
                        </ul>
                        <!-- discount -->

                        <!-- payment ways -->
                        <div class="section-header">
                            <img src="/template/images/Subtract-pin.svg" alt=""/>
                            روش پرداخت
                        </div>
                        <div>

                            <ul class="d-flex flex-wrap" v-if="wallet.amount < order.total_price + shipping.price">
                                <li class="ml-3 mr-3">
                                    <input
                                            v-bind="$store.getters.getWallet < getPrice ? disabled : ''"
                                            v-model="paymentWay"
                                            name="paymentWay"
                                            value="combination"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                </li>
                                <li class="ml-3 mr-3">
                                    <i class="fa fa-money"></i>
                                    پرداخت با کیف پول
                                    <span class="text-danger">
                       موجودی
                    <span v-html="new Intl.NumberFormat().format(wallet.amount)"></span>
                   ریال
                  </span>
                                    <span class="text-danger">
                    (مازاد  مبلغ به صورت آنلاین دریافت خواهد شد)
                  </span>
                                </li>
                            </ul>
                            <ul class="d-flex flex-wrap" v-else>
                                <li class="ml-3 mr-3">
                                    <input
                                            v-bind="$store.getters.getWallet < getPrice ? disabled : ''"
                                            v-model="paymentWay"
                                            name="paymentWay"
                                            value="wallet"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                </li>
                                <li class="ml-3 mr-3">
                                    <i class="fa fa-money"></i>
                                    پرداخت با کیف پول
                                    <span class="text-danger">
                       موجودی
                    <span v-html="new Intl.NumberFormat().format(wallet.amount)"></span>
                   ریال
                  </span>

                                </li>
                            </ul>

                            <ul class="d-flex flex-wrap">
                                <li class="ml-3 mr-3">
                                    <input
                                            v-bind="$store.getters.getWallet < getPrice ? disabled : ''"
                                            v-model="paymentWay"
                                            name="paymentWay"
                                            value="online"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                </li>
                                <li class="ml-3 mr-3">
                                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                                    پرداخت آنلاین
                                </li>
                            </ul>
                            <label v-if="errors.paymentWay" style="color: red">
                                انتخاب روش پرداخت الزامی است!
                            </label>
                        </div>
                        <!-- payment ways -->


                        <!-- details -->
                        <div class="" v-if="options.value==1">
                            <div class="section-header">
                                <img src="/template/images/cart-info.svg" alt=""/>
                                گزینه‌های خرید
                                <span class="text-danger" v-if="discountInput!=false">
                (به دلیل استفاده از کد تخفیف، این گزینه‌ها غیرفعال شده است.)
              </span>
                                <span class="text-danger" v-if="discountInput==false">
               (شما می‌توانید یکی از این روش‌ها را انتخاب کنید.)
              </span>

                            </div>
                            <div  v-if="discountInput==false">
                                <!-- Installment Tow-->
                                <div class="px-3" v-if="(base <= (shipping.price + order.total_price)) && (middle > (shipping.price + order.total_price))">
                                    <!-- Installment -->
                                    <div  >
                                        <input
                                                v-bind="$store.getters.getWallet < getPrice ? disabled : ''"
                                                v-model="paymentOptions"
                                                name="paymentOptions"
                                                value="prepayment_tow"
                                                type="radio"
                                                class="circle-b circle-one"

                                        />
                                        پرداخت اقساطی
                                        (دو قسط ماهیانه)
                                        برای مبالغ
                                        {{ new Intl.NumberFormat().format(base) }} ریال
                                        تا
                                        {{ new Intl.NumberFormat().format(middle) }} ریال

                                    </div>
                                    <div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <p>
                                                    مبلغ پیش پرداخت
                                                    {{ new
                                                    Intl.NumberFormat().format(((order.total_price)/100*prepayment_tow))
                                                    }} ریال
                                                    <span>(
                                                        به میزان
                                                        {{prepayment_tow}}%
                                                        کل مبلغ
                                                   ) </span>
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <p>
                                                    مبلغ پرداخت اقساط ماهیانه
                                                    {{ new
                                                    Intl.NumberFormat().format((order.total_price)/100*(100-prepayment_tow)/2)
                                                    }} ریال
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <!-- Installment Three-->
                                <div class="px-3" v-if="middle < (shipping.price + order.total_price)" >
                                    <!-- Installment -->
                                    <div >
                                        <input
                                                v-bind="$store.getters.getWallet < getPrice ? disabled : ''"
                                                v-model="paymentOptions"
                                                name="paymentOptions"
                                                value="prepayment_three"
                                                type="radio"
                                                class="circle-b circle-one"

                                        />
                                        خرید اقساطی
                                        (سه قسط ماهیانه)
                                        برای مبالغ
                                        {{ new Intl.NumberFormat().format(middle) }} ریال
                                        به بالا


                                    </div>
                                    <div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <p>
                                                    مبلغ پیش پرداخت
                                                    {{ new
                                                    Intl.NumberFormat().format(((order.total_price)/100*prepayment_three))
                                                    }} ریال
                                                    <span>(
                                                        به میزان
                                                        {{prepayment_three}}%
                                                        کل مبلغ
                                                   ) </span>
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <p>
                                                    مبلغ پرداخت اقساط ماهیانه
                                                    {{ new
                                                    Intl.NumberFormat().format((order.total_price)/100*(100-prepayment_three)/3)
                                                    }} ریال
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>

                            <!--            Option -->
                            <ul class="" v-if="discountInput==false">

                                <li class="ml-3 mr-3">

                                    <input
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="offer20"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    20% از مبلغ خرید در کیف پولتان شارژ شود.
                                    <span class="text-danger">
               (شامل کالاهای تخفیف‌دار و خرید با کد تخفیف نمی‌شود.)
              </span>
                                </li>
                                <li class="ml-3 mr-3">
                                    <input
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="randomOff"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    به صورت اتفاقی (رندم) کد تخفیف 10 تا 40 درصد دریافت کنید.
                                </li>
                                <li class="ml-3 mr-3" v-if="limit_delivery.value <= order.total_price">
                                    <input

                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="deliver"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    هزینه‌ی ارسال رایگان
                                    (برای خریدهای بیش از {{ new Intl.NumberFormat().format(limit_delivery.value) }}
                                    ریال)
                                </li>
                                <li class="ml-3 mr-3" v-else>
                                    <input
                                            disabled
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="deliver"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    هزینه‌ی ارسال رایگان
                                    (برای خریدهای بیش از {{ new Intl.NumberFormat().format(limit_delivery.value) }}
                                    ریال)
                                </li>
                                <li class="ml-3 mr-3">

                                    <input
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value=""
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    هیچ‌کدام

                                </li>
                            </ul>
                            <ul class="" v-if="discountInput==true">

                                <li class="ml-3 mr-3">

                                    <input
                                            disabled
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="offer20"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    20% از مبلغ خرید در کیف پولتان شارژ شود.
                                    <span class="text-danger">
               (شامل کالاهای تخفیف‌دار و خرید با کد تخفیف نمی‌شود.)
              </span>
                                </li>
                                <li class="ml-3 mr-3">
                                    <input
                                            disabled
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="randomOff"
                                            type="radio"
                                            class="circle-b circle-one"
                                    />
                                    به صورت اتفاقی (رندم) کد تخفیف 10 تا 40 درصد دریافت کنید.
                                </li>
                                <li class="ml-3 mr-3">
                                    <input
                                            disabled
                                            v-model="paymentOptions"
                                            name="paymentOptions"
                                            value="deliver"
                                            type="radio"
                                            class="circle-b circle-one"/>

                                    هزینه‌ی ارسال رایگان
                                    (برای خریدهای بیش از {{ new Intl.NumberFormat().format(limit_delivery.value) }}
                                    ریال)
                                </li>
                            </ul>


                        </div>

                        <!-- factor extra -->
                        <div class="section-header">
                            سایر گزینه‌ها
                        </div>
                        <div>
                            <ul class="d-flex flex-wrap">
                                <li class="ml-3 mr-3">
                                    <input type="checkbox" v-model="isgift">
                                </li>
                                <li class="ml-3 mr-3">
                                    این سفارش هدیه است
                                </li>
                                <li v-if="isgift" class="col-12">
                  <textarea class="input" v-model="giftdescription"
                            placeholder="محل ورود توضیحات متن هدیه"></textarea>
                                </li>
                            </ul>
                            <ul class="d-flex flex-wrap">
                                <li class="ml-3 mr-3">
                                    <input type="checkbox" v-model="isfactorsend">
                                </li>
                                <li class="ml-3 mr-3">
                                    ارسال فاکتور
                                </li>
                            </ul>
                        </div>
                        <!-- factor extra -->


                        <div class="details">
                            <!-- <ul class="l2">
                          <li style="padding-right: 33px">تخفیفات</li>
                          <li class="position-left">۵۰،۰۰۰ریال</li>
                        </ul> -->

                            <ul class="d-flex spaceing-ul alert alert-light">
                                <li>جمع کل خرید</li>

                                <li>

                                    {{ new Intl.NumberFormat().format(order.total_price) }} ریال

                                </li>
                            </ul>


                            <ul class="d-flex spaceing-ul alert alert-primary"
                                v-if="shipping && paymentOptions!='deliver'">
                                <li>هزینه‌ی ارسال</li>
                                <li>
                                    {{ new Intl.NumberFormat().format(shipping.price) }} ریال
                                </li>
                            </ul>
                            <ul v-if="discountAmount > 0" class="d-flex alert alert-info">
                                <li class="pl-3 pr-3">تخفیف کپن</li>
                                <li class="pl-3 pr-3">{{ discountAmount }} ریال</li>
                            </ul>

                            <hr class="hr-line"/>
                            <ul class=" spaceing-ul alert alert-success text-left">
                                <li>
                                    مبلغ قابل پرداخت :
                                    {{ getPrice }} ریال
                                </li>
                                <li class="text-danger" v-if="paymentWay=='wallet'">
                                    مانده کیف پول :
                                    {{ new Intl.NumberFormat().format(wallet.amount-(shipping.price +
                                    order.total_price)) }} ریال
                                </li>
                                <li class="text-danger" v-if="paymentWay=='combination'">
                                     <span v-if="paymentOptions=='prepayment_tow'">
                                                  مازاد پرداخت آنلاین :
                                    {{ new Intl.NumberFormat().format((((order.total_price)/100*prepayment_tow)+shipping.price)-wallet.amount) }} ریال
                                     </span>
                                    <span v-else-if="paymentOptions=='prepayment_three'">
                                               مازاد پرداخت آنلاین :
                                    {{ new Intl.NumberFormat().format((((order.total_price)/100*prepayment_three)+shipping.price)-wallet.amount) }} ریال

                                    </span>
                                    <span v-else>
                                      مازاد پرداخت آنلاین :
                                    {{ new Intl.NumberFormat().format(wallet.amount-(shipping.price +
                                    order.total_price)) }} ریال

                                         </span>


                                </li>

                            </ul>

                            <ul>
                                <li>
                                    <input
                                            class="submit-btn"
                                            type="button"
                                            @click="applyCheckout()"
                                            value="ثبت سفارش"

                                    />

                                    <router-link class="submit-btn text-light mx-1" to="/cart">بازگشت به سبد خرید
                                    </router-link>

                                </li>

                            </ul>
                        </div>
                        <!-- details -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Breadcrumb from "@/components/breadcrumb.component";
    import Spinner from "@/components/Spinner.component";


    // import Paymentways from "@/components/Paymentways.component";
    export default {
        name: "Checkout",
        components: {
            Breadcrumb,
            Spinner
            //Paymentways
        },
        data() {
            return {
                loading: true,
                limit_delivery: [],
                options: [],
                addresses: [],
                wallet: [],
                addressId: null,
                shippings: [],
                shipping: null,
                paymentWay: "",
                paymentOptions: "",
                description: "",
                discountCode: null,
                order: {},
                errors: {
                    paymentWay: false,
                    // paymentOptions:false,
                    shipping: false,
                    address: false,
                    discount: null
                },
                discountInput: false,
                discountInputText: "در صورت داشتن کد تخفیف آن را وارد کنید.",
                discountAmount: 0,
                isgift: false,
                giftdescription: '',
                isfactorsend: false,

                base: null,
                middle: '',
                prepayment_tow: '',
                prepayment_three: ''
            };
        },
        mounted() {

        },

        computed: {
            getPrice() {
                let deliver_price;
                if (this.paymentOptions == 'deliver') {
                    deliver_price = this.order.total_price
                } else {
                    deliver_price = this.order.total_price +
                        (this.shipping && this.shipping.price ? this.shipping.price : 0)

                }
                return new Intl.NumberFormat().format(
                    deliver_price
                );
            }
        },
        created() {
            this.$store.dispatch("getLimitDelivery").then((res) => {
                this.limit_delivery = res.limit
                this.options = res.options
                this.base = res.installment.base
                this.middle = res.installment.middle
                this.prepayment_tow = res.installment.prepayment_tow
                this.prepayment_three = res.installment.prepayment_three
            });
            //Wallet Info
            this.$store.dispatch("getWalletBalance").then(result => {
                this.wallet = result.wallet;
                this.loadingBalance = false;
            });
            // //enable loader
            // this.$store.commit("setLoader", true);

            this.$store.dispatch("getAddresses").then(result => {
                this.addresses = result;
                this.addressId = result[0].id;
            });

            this.$store.dispatch("getShipping").then(result => {
                this.shippings = result;
                this.shipping = result[0];
            });

            if (this.$store.getters.getOrder) {
                this.order = this.$store.getters.getOrder;
                if (this.order.total_price == null) {
                    this.$router.push("/cart");
                }
                //disable loader
                this.loading = false;
            } else {
                this.$store
                    .dispatch("getOrder", {
                        orderId: this.$route.params.orderId
                    })
                    .then(order => {
                        this.order = order.order;
                        if (this.order.total_price == null) {
                            this.$router.push("/cart");
                        }
                        //disable loader
                        this.loading = false;
                    });
            }

        },
        methods: {
            applyDiscount() {
                if (this.discountCode != null) {
                    //enable loader
                    this.loading = true;

                    this.$store
                        .dispatch("applyDiscount", {
                            orderId: this.$route.params.orderId,
                            code: this.discountCode
                        })
                        .then(result => {
                            if (result.success === true) {
                                this.$swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: result.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                this.discountInput = true;
                                this.discountInputText = this.discountCode;
                                this.discountAmount = result.amount;
                                this.$store
                                    .dispatch("getOrder", {
                                        orderId: this.$route.params.orderId
                                    })
                                    .then(order => {
                                        this.order = order.order;
                                    });
                            } else {
                                this.$swal.fire({
                                    position: "center",
                                    icon: "warning",
                                    title: result.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }
                            //disable loader
                            this.loading = false;
                        });
                } else {
                    this.$swal.fire({
                        position: "top-end",
                        icon: "warning",
                        title: "جهت استفاده از تخفیف کد مورد نیاز است !",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            },
            applyCheckout: function () {
                //check address
                if (this.paymentWay == 'wallet') {
                    if (this.paymentOptions == "offer20") {
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: '20 درصد از مبلغ خرید در کیف پول شما شارژ شد',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }

                    if (this.paymentOptions == "randomOff") {
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'کد تخفیف هدیه به صورت پیامک برای شما ارسال شد',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }
                }

                if (this.paymentWay == 'combination') {
                    if (this.paymentOptions == "offer20") {
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'در صورت پرداخت 20 درصد از مبلغ خرید در کیف پول شما شارژ میگردد',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }

                    if (this.paymentOptions == "randomOff") {
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'در صورت پرداخت کد تخفیف هدیه به صورت پیامک برای شما ارسال میگردد',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }
                }

                if (this.paymentWay == 'online') {
                    if (this.paymentOptions == "offer20") {
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'در صورت پرداخت 20 درصد از مبلغ خرید در کیف پول شما شارژ میگردد',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }

                    if (this.paymentOptions == "randomOff") {
                        this.$swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'در صورت پرداخت کد تخفیف هدیه به صورت پیامک برای شما ارسال میگردد',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }
                }


                if (this.paymentOptions == "deliver") {

                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'هزینه ارسال از خرید شما کسر گردید',
                        showConfirmButton: false,
                        timer: 5000
                    })
                }

                if (!this.addressId) {
                    this.errors.address = true;
                    setTimeout(() => {
                        this.errors.address = false;
                    }, 5000);
                    return;
                }

                //check shipping
                if (!this.shipping || !this.shipping.id) {
                    this.errors.shipping = true;
                    setTimeout(() => {
                        this.errors.shipping = false;
                    }, 5000);
                    return;
                }

                //check payment way
                if (!this.paymentWay) {
                    this.errors.paymentWay = true;
                    setTimeout(() => {
                        this.errors.paymentWay = false;
                    }, 5000);
                    return;
                }

                //check paymentOptions
                // if (!this.paymentOptions) {
                //   this.errors.paymentOptions = true;
                //   setTimeout(() => {
                //     this.errors.paymentOptions = false;
                //   }, 5000);
                //   return;
                // }

                //enable loader
                this.loading = true;

                //     //show unauthorized user
                //     this.$swal
                //       .fire({
                //         position: "center",
                //         icon: "warning",
                //         title: "لطفا وارد حساب کاربری خود شوید !",
                //         showConfirmButton: false,
                //         timer: 1500,
                //       })
                //       .then(() => {
                //         this.$router.push({ name: "auth" });
                //         return;
                //       });

                this.$store
                    .dispatch("applyCheckout", {
                        orderId: this.$route.params.orderId,
                        addressId: this.addressId,
                        shippingId: this.shipping.id,
                        paymentWay: this.paymentWay,
                        paymentOptions: this.paymentOptions,
                        description: this.description,
                        isgift: this.isgift,
                        giftdescription: this.giftdescription,
                        isfactorsend: this.isfactorsend,
                    })
                    .then(result => {
                        if (!result) {
                            let vm = this;
                            if (
                                vm.$store.getters.getShoppingError &&
                                vm.$store.getters.getShoppingError == "quantityLimited"
                            ) {
                                //out of product
                                this.$swal
                                    .fire({
                                        position: "center",
                                        icon: "error",
                                        title: vm.$store.getters.getShoppingMessage,
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    .then(() => {
                                        vm.$router.push("/cart");
                                        vm.loading = false;
                                    });
                            } else {
                                //other
                                this.$swal
                                    .fire({
                                        position: "center",
                                        icon: "error",
                                        title: vm.$store.getters.getShoppingMessage,
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                    .then(() => {
                                        vm.$router.push({name: "Home"});
                                        vm.loading = false;
                                    });
                                this.loading = false;
                            }
                        } else {
                            //redirect
                            if (result.url) {
                                window.location.href = result.url;
                            }
                        }
                    });

                // }
                // });
                //   }
                // });
            }
        }
    };
</script>

<style scoped>
    ul {
        list-style: none;
    }

    input[type="radio"] {
        cursor: pointer;
    }

    .spaceing-ul li {
        margin: 0 1rem;
        min-width: 100px;
        text-align: center;
    }

    .section-header {
        background-color: #e7e7e7;
        padding: 5px;
        border-radius: 5px;
        margin: 1rem auto;
    }

    .details {
        width: 300px;
        text-align: center;
        margin-right: auto;
    }

    @media screen and (max-width: 450px) {
        .details {
            width: 100%;
            margin: auto;
        }
    }

    /* discount */
    .discount {
        display: flex;
    }

    .discount input {
        height: 40px;
        margin: 0;
        min-width: 250px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .discount input::placeholder {
        font-size: 12px;
    }

    .discount button {
        font-size: 14px;
        height: 40px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    @media screen and (max-width: 450px) {
        .discount {
            justify-content: center;
        }

        .discount input {
            min-width: 200px;
        }

        .discount button {
            font-size: 10px;
        }
    }

    /* discount */
    /* basket flow */
    .basket-flow {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        padding: 2rem 0;
    }

    .basket-flow li:not(.line) {
        text-align: center;
        margin: 0 5px;
    }

    .basket-flow li img {
        width: 50px;
        height: 50px;
    }

    .basket-flow li.line {
        width: 50px;
        height: 5px;
        background-color: gray;
    }

    .basket-flow li.line.active {
        background-color: #1c403e;
    }

    @media screen and (max-width: 600px) {
        .basket-flow li {
            font-size: 11px;
        }

        .basket-flow li img {
            width: 20px;
            height: 20px;
        }

        .basket-flow li.line {
            width: 20px;
            height: 3px;
        }
    }

    /* basket flow */
</style>
