<template>
  <ul class="breadcrumb d-flex align-items-center w-auto">
    <li>
      <router-link to="/">خانه</router-link>
      <i
        v-if="sectionName || (sectionNames && sectionNames.length !== 0)"
        class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"
      ></i>
    </li>
    <li v-if="sectionName">
      <router-link :to="sectionAddr">{{ sectionName }}</router-link>
      <!-- <i class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"></i> -->
    </li>
    <li v-for="(item, i) in sectionNames" :key="i">
      <router-link :to="'/category/' + item.slug">{{ item.name }}</router-link>
      <i
        v-if="i !== sectionNames.length - 1"
        class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"
      ></i>
    </li>
    <li v-if="currentPageName">
      <i
        v-if="currentPageName"
        class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"
      ></i>
      {{ currentPageName }}
    </li>
  </ul>
</template>
<script>
export default {
  name: "breadcrumb",
  props: ["currentPageName", "sectionName", "sectionAddr", "sectionNames"]
};
</script>
<style scoped>
.breadcrumb {
  margin-bottom: 0 !important;
  background-color: #1c403e !important;
  color: white !important;
}
.breadcrumb a {
  color: white !important;
}
.breadcrumb i {
  font-size: 20px;
}
</style>
