var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Breadcrumb',{attrs:{"currentPageName":"سبد خرید شما"}}),(_vm.loading)?_c('Spinner'):_c('div',[_c('div',{staticClass:"row"},[_c('ul',{staticClass:"basket-flow"},[_c('li',[_c('img',{attrs:{"src":"/template/images/shoping-icon-top.svg","alt":"shoping"}}),_c('p',[_vm._v("سبد خرید")])]),_c('li',{staticClass:"line active"}),_c('li',{staticClass:"line"}),_c('li',[_c('img',{attrs:{"src":"/template/images/address-icon.svg","alt":"address"}}),_c('p',[_vm._v("اطلاعات ارسال")])]),_c('li',{staticClass:"line"}),_c('li',{staticClass:"line"}),_c('li',[_c('img',{attrs:{"src":"/template/images/cart-shoping.svg","alt":"cart"}}),_c('p',[_vm._v("ثبت نهایی")])])])]),_c('div',{staticClass:"custom-container"},[_c('div',{staticClass:"custom-table-container"},[(_vm.loading)?_c('Spinner'):_c('table',{staticClass:"custom-table"},[_c('thead',[_c('tr',{staticClass:"h"},[_c('th',[_vm._v("نام و نام خانوادگی")]),_c('th',[_vm._v("کد ملی")]),_c('th',[_vm._v("آدرس")]),_c('th',[_vm._v("کد پستی")]),_c('th',[_vm._v("شماره همراه")]),_c('th',[_vm._v("ویرایش")]),_c('th',[_vm._v("حذف")])])]),_c('tbody',_vm._l((_vm.addresses),function(item,index){return _c('tr',{key:item[index]},[_c('td',[_vm._v(" "+_vm._s(item.fullname)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.melicode)+" ")]),_c('td',[_vm._v(_vm._s(item.address))]),_c('td',[_vm._v(_vm._s(item.postalcode))]),_c('td',[_vm._v(_vm._s(item.phone))]),_c('td',[_c('router-link',{staticClass:"m-auto fa fa-pencil",attrs:{"tag":"i","to":{
                    path: '/profile/address/edit/' + item.id,

                    query: {
                      isCart: true
                    }
                  },"type":"button"}})],1),_c('td',[_c('i',{staticClass:"delete-icon m-auto fa fa-remove",on:{"click":function($event){return _vm.deleteAddress(item.id)}}})])])}),0)])],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('router-link',{staticClass:"submit-btn mt-3 ml-1",attrs:{"tag":"input","type":"button","value":"افزودن آدرس جدید","to":{
            name: 'AddAddress',
            query: {
              isCart: true
            }
          }}}),_c('button',{staticClass:"submit-btn mt-3 mr-1",attrs:{"tag":"input","type":"button"},on:{"click":_vm.gotoCheckout}},[_vm._v(" تکمیل خرید ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }