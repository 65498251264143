import axios from "axios";
import { env } from "../../../enums";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getFavoriteSlide() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/slider01", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getBestSellSlide() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/slider03", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getLatestProductSlide() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/slider02", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getLastPostSlide() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/slider04", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getproductcategories() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/productcategories", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getAmazingProducts() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/amazingproducts", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getHomePage() {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/homepage/collect", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getRelativeProduct(state, productid) {
      return new Promise(resolve => {
        axios
          .get(env.base_url + "/product/relative/" + productid, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          })
          .then(result => {
            return resolve(result.data);
          });
      });
    },
    getLatestProducts() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/latest-products").then(result => {
          if (result.status === 200) {
            resolve(result.data.items);
          }
          reject({});
        });
      });
    },
    getOfferProducts() {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + "/module/special-deal").then(result => {
          if (result.status === 200) {
            resolve(result.data);
          }
          reject({});
        });
      });
    }
  }
};
