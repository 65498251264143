<template>
  <section>
    <Breadcrumb currentPageName="سبد خرید شما"></Breadcrumb>

    <div class="container-fluid body">
      <div class="row">
        <div class="col-xl-12" style="text-align: center">
          <img src="/template/images/success.svg" class="m-3" alt="" />
          <!-- <p id="first-p">پرداخت با موفقیت انجام نشد!</p> -->

          <p>
            {{
              message && message != ""
                ? message
                : "مشکلی در پرداخت رخ داده است !"
            }}
          </p>
          <p style="margin-top: -10px">
            در صورتی که مبلغی از حسابتان کسر شده است، تا 72 ساعت دیگر به حسابتان
            باز خواهد گشت!
          </p>
          <div
            class="d-flex flex-wrap justify-content-center align-content-center"
          >
            <router-link
              tag="input"
              class="submit-btn m-2"
              type="button"
              value="خرید خود را ادامه دهید"
              to="/"
            ></router-link>
            <router-link
              tag="input"
              class="info-btn m-2"
              type="button"
              value="مشاهده‌ی سفارش‌ها"
              to="/profile/orders"
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";

export default {
  components: { Breadcrumb },
  data() {
    return {
      message: ""
    };
  },
  created() {
    this.message = this.$route.query.message;
  },
  mounted() {
    this.$store.dispatch("getBasketReq");
  }
};
</script>
