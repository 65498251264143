import Axios from "axios";
import {env} from "../../../enums";

export default {
    state: {
        basket: [],
        showBasket: false,
        orderId: null,
        order: {},
        status: null,
        message: "",
        error: ""
    },
    getters: {
        getBasket(state) {
            state.basket = localStorage.getItem("basket")
                ? JSON.parse(localStorage.getItem("basket"))
                : [];
            return state.basket;
        },
        getOrder(state) {
            return state.order;
        },
        orderId(state) {
            return state.orderId;
        },
        showBasketStatus(state) {
            return state.showBasket;
        },
        getStatus(state) {
            return state.status;
        },
        getShoppingMessage(state) {
            return state.message;
        },
        getShoppingError(state) {
            return state.error;
        }
    },
    mutations: {
        changeBasketStatus(state, status) {
            state.showBasket = status;
        },
        registeredOrder(state, orderId) {
            state.orderId = orderId;
        },
        setBasket(state, basket) {
            state.basket = basket;
        },
        setOrder(state, order) {
            state.order = order;
        },
        setBasketStatus(state, status) {
            state.showBasket = status;
        },
        setShoppingMessage(state, message) {
            state.message = message;
        },
        setShoppingError(state, error) {
            state.error = error;
        }
    },
    actions: {
        setProductBasket(context, {data}) {
            context.commit("setBasket", data);
        },
        setBasketStatus(context, {status}) {
            context.commit("changeBasketStatus", status);
        },
        setStatus(context, {status}) {
            context.commit("setStatus", status);
        },
        setRegisterOrder(context, {options}) {
            context.commit("registerOrder", options);
        },
        reqRegisterOrder(context, {products}) {
            return new Promise(function (resolve, reject) {
                Axios.post(
                    env.base_url + "/account/order",
                    {
                        addressId: 1,
                        products
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(result => {
                        if (result.status == 200) {
                            context.commit("setOrder", result.data.order);
                            resolve({
                                status: 200,
                                path: {
                                    name: "Checkout",
                                    params: {
                                        orderId: result.data.order_id
                                    }
                                }
                            });
                        }
                        reject();
                    })
                    .catch(err => {
                        resolve({
                            status: err.response.status,
                            data: err.response.data
                        });
                    });
            });
        },
        getOrder(context, {orderId}) {
            return new Promise(function (resolve, reject) {
                Axios.get(env.base_url + "/account/order/" + orderId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then(result => {
                    if (result.status === 200) {
                        resolve(result.data.item);
                    }
                    reject();
                });
            });
        },
        applyCheckout(
            context,
            {orderId, addressId, paymentWay,paymentOptions, shippingId, description, isgift, giftdescription, isfactorsend}
        ) {
            return new Promise(function (resolve) {
                Axios.post(
                    env.base_url + "/account/order/" + orderId,
                    {
                        addressId,
                        shippingId,
                        paymentWay,
                        paymentOptions,
                        description,
                        isgift, giftdescription, isfactorsend
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(result => {
                        if (result.data.success == true) {
                            resolve(result.data);
                        } else if (result.data.message) {
                            context.commit("setShoppingMessage", result.data.message);
                            if (result.data.error) {
                                context.commit("setShoppingError", result.data.error);
                            }
                            resolve(false);
                        }
                    })
                    .catch(error => {
                        if (error.response.data && error.response.data.message) {
                            context.commit("setShoppingMessage", error.response.data.message);
                            if (error.response.data.error) {
                                context.commit("setShoppingError", error.response.data.error);
                            }
                            resolve(false);
                        } else {
                            context.commit(
                                "setShoppingMessage",
                                "مشکلی در پرداخت به وجود آمده است"
                            );
                        }
                        resolve(false);
                    });
            });
        },
        applyDiscount(context, {orderId, code}) {
            return new Promise(function (resolve) {
                Axios.post(
                    env.base_url + "/coupon/" + orderId,
                    {
                        code
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                ).then(result => {
                    resolve(result.data);
                });
            });
        },
        getOrderInfo(context, {orderId}) {
            return new Promise(function (resolve, reject) {
                Axios.get(env.base_url + "/account/order/" + orderId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then(result => {
                    if (result.status == 200) {
                        resolve(result.data.item);
                    }
                    reject();
                });
            });
        },
        getOrders(context, {orderType, page}) {
            return new Promise(function (resolve) {
                Axios.get(
                    env.base_url + "/account/order?page=" + page + "&filter=" + orderType,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(result => {
                        if (result.status == 200) {
                            resolve(result.data.item);
                        }
                        resolve([]);
                    })
                    .catch(() => {
                        resolve([]);
                    });
            });
        },
        getAuthBasket() {
            return new Promise(function (resolve, reject) {
                Axios.get(env.base_url + "/cart/get", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then(result => {
                    if (result.status == 200) {
                        resolve(result.data.item);
                    }
                    reject();
                });
            });
        },
        //basket usable functions
        addToBasket(context, {product}) {
            //compare and add to basket locally
            // return new Promise(function(resolve) {
            let basket = context.getters.getBasket;

            //calculate max and min limit locally
            //let min = product.min_limit ? this.product.min_limit : 1;
            let max =
                product.max_limit && product.max_limit < product.publish_quantity
                    ? product.max_limit
                    : product.publish_quantity;

            //check if product exist in basket
            let res = basket.find(
                obj =>
                    obj.productId === product.productId && obj.options === product.options
            );
            if (res) {
                //update res
                res.quantity =
                    Number(res.quantity) + Number(product.quantity) > max
                        ? max
                        : Number(res.quantity) + Number(product.quantity);
                //remove from basket
                basket = basket.filter(
                    obj =>
                        obj.productId !== product.productId ||
                        obj.options !== product.options
                );

                //push in basket
                basket.push(res);
            } else {
                //push in basket
                basket.push({
                    image: product.image,
                    productId: product.productId,
                    slug: product.slug,
                    name: product.name,
                    price: product.price,
                    min_limit: product.min_limit,
                    max_limit: max,
                    quantity: product.quantity,
                    brand: product.brand,
                    publish: product.publish, //pass all publish
                    publish_quantity: product.publish_quantity,
                    options: product.options,
                    special_price: product.special_price
                });
            }

            //commit and save in localstorage
            localStorage.setItem("basket", JSON.stringify(basket));
            context.commit("setBasket", basket);

            //show basket
            context.commit("setBasketStatus", true);
            //   resolve(product);
            // });
        },
        updateBasket(context, {product}) {
            return new Promise(function (resolve) {
                let basket = context.getters.getBasket;

                if (product && product.productId) {
                    let res = basket.find(
                        obj =>
                            obj.productId === product.productId &&
                            obj.options === product.options
                    );
                    //remove from basket
                    basket = basket.filter(
                        obj =>
                            obj.productId !== product.productId ||
                            obj.options !== product.options
                    );

                    //push in basket
                    basket.push(res);

                    //commit and save in localstorage
                    localStorage.setItem("basket", JSON.stringify(basket));
                    context.commit("setBasket", basket);
                }
                resolve(basket);
            });
        },
        removeFromBasket(context, {product}) {
            // return new Promise(function(resolve) {
            let basket = context.getters.getBasket;

            //check if product exist in basket
            let res = basket.find(
                obj =>
                    obj.productId === product.productId && obj.options === product.options
            );
            if (res) {
                //remove from basket
                basket = basket.filter(
                    obj =>
                        obj.productId !== product.productId ||
                        obj.options !== product.options
                );
            }

            //commit and save in localstorage
            localStorage.setItem("basket", JSON.stringify(basket));
            context.commit("setBasket", basket);
            // resolve(product);
            // });
        },
        addToBasketReq(context, {product}) {
            return new Promise(function (resolve) {
                Axios.post(
                    env.base_url + "/cart/add",
                    {
                        image: product.image,
                        productId: product.productId,
                        productTitle: product.name,
                        price: product.price,
                        min_limit: product.min_limit,
                        max_limit: product.max_limit,
                        quantity: product.quantity,
                        publish: product.publish,
                        options: product.options
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(() => {
                        resolve(true); //success
                    })
                    .catch(() => {
                        resolve(false); //failed
                    });
            });
        },
        updateProductBasketReq(context, {product}) {
            return new Promise(function (resolve) {
                Axios.post(
                    env.base_url + "/cart/update",
                    {
                        image: product.image,
                        productId: product.productId,
                        productTitle: product.name,
                        price: product.price,
                        min_limit: product.min_limit,
                        max_limit: product.max_limit,
                        quantity: product.quantity,
                        publish: product.publish,
                        options: product.options
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(() => {
                        resolve(true); //success
                    })
                    .catch(() => {
                        resolve(false); //failed
                    });
            });
        },
        removeFromBasketReq(context, {product}) {
            return new Promise(function (resolve) {
                Axios.post(
                    env.base_url + "/cart/delete",
                    {
                        image: product.image,
                        productId: product.productId,
                        productTitle: product.name,
                        price: product.price,
                        min_limit: product.min_limit,
                        max_limit: product.max_limit,
                        quantity: product.quantity,
                        publish: product.publish,
                        options: product.options
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(() => {
                        resolve(true); //success
                    })
                    .catch(() => {
                        resolve(false); //failed
                    });
            });
        },
        getBasketReq(context) {
            //get basket from server and add to basket again
            return new Promise(function (resolve) {
                //just get baskets
                Axios.get(env.base_url + "/basket/get", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then(result => {
                    if (result.status === 200) {
                        //transform data
                        let items = [];
                        for (var i = 0; i < result.data.items.length; i++) {
                            if (result.data.items[i].publish) {
                                let obj = {
                                    image: result.data.items[i].image,
                                    productId: result.data.items[i].productId,
                                    slug: result.data.items[i].slug,
                                    name: result.data.items[i].productTitle,
                                    brand: result.data.items[i].brand,
                                    price: result.data.items[i].publish.back_cover_price,
                                    min_limit: result.data.items[i].min_limit,
                                    max_limit: result.data.items[i].max_limit,
                                    quantity: result.data.items[i].quantity,
                                    publish: result.data.items[i].publish.publish,
                                    publish_quantity: result.data.items[i].publish.quantity,
                                    options: result.data.items[i].publish.id,
                                    special_price: result.data.items[i].publish.special_price
                                };
                                items.push(obj);
                            }
                        }

                        //commit
                        context.commit("setBasket", items);
                        localStorage.setItem("basket", JSON.stringify(items));

                        //returns original items
                        resolve(items);

                        // let products = result.data.items;
                        // for (var i = 0; i < products.length; i++) {
                        //     context.dispatch("addToBasket", {
                        //         product: products[i]
                        //     });
                        // }
                    }
                });
            });
        },
        updateBasketReq() {
            return new Promise(function (resolve) {
                //sync basket localstorage and database
                if (
                    localStorage.getItem("basket") &&
                    JSON.parse(localStorage.getItem("basket")).length > 0
                ) {
                    //send data and get basket
                    let products = JSON.parse(localStorage.getItem("basket"));
                    Axios.post(
                        env.base_url + "/cart/update-group",
                        {
                            products
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    )
                        .then(result => {
                            if (result.status === 200) {
                                resolve(true);
                            }
                        })
                        .catch(() => {
                            resolve(false);
                        });
                }
            });
        },
        registerOrder(context, {options}) {
            return new Promise(function (resolve) {
                Axios.post(
                    env.base_url + "/request-product",
                    {
                        productId: options.productId,
                        attrId: options.attrId,
                        quantity: options.quantity
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                )
                    .then(result => {
                        if (result.status === 200) {
                            resolve(result.status);
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            resolve(error.response.status);
                        }
                    });
            });
        }
    }
};
