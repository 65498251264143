import Post from "@/views/pages/Post.vue";
import Blog from "@/views/pages/Blog.vue";
export default [
  {
    path: "/post/:slug",
    name: "post",
    component: Post
  },
  {
    path: "/blog/",
    name: "blog",
    component: Blog
  }
];
