import Vue from "vue";
import Vuex from "vuex";
import authOTP from "./modules/auth-otp.store";
// import authUP from "./modules/auth-up.store";
import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

import product from "./modules/product.store";
import basket from "./modules/shopping-card.store";
import profile from "./modules/profile.store";
import information from "./modules/information.store";
import settings from "./modules/settings.store";
import slider from "./modules/slider.store";
import category from "./modules/category.store";
import filter from "./modules/filter.store";
import search from "./modules/search.store";
import wallet from "./modules/wallet.store";
import paymentways from "./modules/paymentways.store";
import sliders from "./modules/sliders.store";
import blog from "./modules/blog.store";
import productsList from "./modules/productsList.store";
import {env} from "../../enums";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        Limit_Delivery: []
    },

    actions:{
        getLimitDelivery({commit}) {
            return new Promise(resolve => {
                axios.get(
                    env.base_url + "/setting_limit_delivery"
                ).then((res) =>{
                    console.log(res.data);
                    commit('getLimitDelivery',res.data)
                         return resolve(res.data);
                })

            } )
        }
    },
    mutations: {
        getLimitDelivery(state,limit){
            state.Limit_Delivery=limit;
        }
    },
    getters: {
        Limit_Delivery(state) {
            return state.Limit_Delivery
        }
    },
    modules: {
        authOTP,
        // authUP,
        product,
        basket,
        profile,
        information,
        settings,
        slider,
        category,
        filter,
        search,
        wallet,
        paymentways,
        sliders,
        blog,
        productsList
    }
});
