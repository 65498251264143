<template>
  <div
    @click="remind(productId)"
    class="info-btn w-max"
    v-bind:class="{ active: active }"
  >
    <span>موجود شد خبر بده :</span>
    <img src="/template/images/bell.svg" alt="heart" width="30px" />
  </div>
</template>

<script>
export default {
  name: "RemindAction",
  data: function() {
    return {
      active: false
    };
  },
  computed: {},
  props: ["productId"],
  methods: {
    remind(productId) {
      if (localStorage.getItem("token") === null) {
        this.$swal.fire({
          position: "center",
          icon: "warning",
          title: "لطفا وارد حساب کاربری خود شوید !",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        if (productId) {
          this.$store.dispatch("remindAction", { productId }).then(res => {
            if (res) {
              this.$swal.fire({
                position: "center",
                icon: "success",
                title: res.message,
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              this.$swal.fire({
                position: "center",
                icon: "warning",
                title: "خطا در ثبت ",
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
        }
      }
    }
  }
};
</script>
<style scoped></style>
