<template>
  <section>
    <Breadcrumb
      :sectionNames="breadcrumbs"
      :currentPageName="product.name"
    ></Breadcrumb>

    <div class="wrapper">
      <div class="custom-container w-100 pt-5 pb-5">
        <Spinner v-if="loading"></Spinner>
        <div v-else>
          <div
            class="d-flex flex-wrap align-items-center justify-content-around"
          >
            <div class="col-custom">
              <!-- gallery -->
              <div class="image-container d-flex" style="height:auto !important;">
              <ul class="slides">
                <li
                  v-for="(image, index) in product.images"
                  :key="'slide' + index"
                >
                  <img
                    :src="
                      image && image.length !== 0
                        ? image
                        : '/template/images/no-image.png'
                    "
                    alt="one"
                    @click="mainImage = image"
                    @error="image = '/template/images/no-image.png'"
                  />
                </li>
              </ul>
              <zoom-on-hover
                class="main-img"
                :img-normal="mainImage && mainImage.length !== 0
                    ? mainImage
                    : '/template/images/no-image.png'"
              ></zoom-on-hover>
              <!-- <img
                class="main-img"
                :src="
                  mainImage && mainImage.length !== 0
                    ? mainImage
                    : '/template/images/no-image.png'
                "
                @error="mainImage = '/template/images/no-image.png'"
                alt=""
              /> -->
            </div>

              <!-- <div class="image-container d-flex">
                <ul class="slides">
                  <li
                    v-for="(image, index) in product.images"
                    :key="'slide' + index"
                  >
                    <img
                      :src="
                        image && image.length !== 0
                          ? image
                          : '/template/images/no-image.png'
                      "
                      alt="one"
                      @click="mainImage = image"
                      @error="image = '/template/images/no-image.png'"
                    />
                  </li>
                </ul>
                <img
                  class="main-img"
                  :src="
                    mainImage && mainImage.length !== 0
                      ? mainImage
                      : '/template/images/no-image.png'
                  "
                  @error="mainImage = '/template/images/no-image.png'"
                  alt=""
                />
              </div> -->
              <div class="d-flex justify-content-center">
                <!-- favorite -->
                <FavoriteAction
                  class="add-to-wishlist mt-3"
                  :productId="product.id"
                ></FavoriteAction>
                <!-- favorite -->
              </div>

              <!-- gallery -->
            </div>
            <div class="col-custom mt-3">
              <!-- details -->
              <div class="details">
                <h5 style="max-width: 500px">
                  {{ product.name }}
                </h5>
                <br />
                <label v-if="product.get_brand">
                  ناشر:
                  <router-link
                    :to="{
                      name: 'search',
                      query: {
                        brand: JSON.stringify([
                          {
                            id: product.get_brand.id,
                            name: product.get_brand.name
                          }
                        ])
                      }
                    }"
                  >
                    {{ product.get_brand ? product.get_brand.name : "" }}
                  </router-link>
                </label>
                <label v-if="product.author">
                  نویسنده:
                  <router-link
                    :to="{
                      name: 'search',
                      query: {
                        author: JSON.stringify([
                          {
                            id: product.author_id,
                            name: product.author
                          }
                        ])
                      }
                    }"
                  >
                    {{ product.author ? product.author : "" }}
                  </router-link>
                </label>
                <label v-if="product.translator">
                  مترجم:
                  <router-link
                    :to="{
                      name: 'search',
                      query: {
                        translator: JSON.stringify([
                          {
                            id: product.translator_id,
                            name: product.translator
                          }
                        ])
                      }
                    }"
                  >
                    {{ product.translator ? product.translator : "" }}
                  </router-link>
                </label>
                <label
                  v-if="
                    Number(publish.quantity) > 0 && Number(publish.quantity) < x
                  "
                >
                  وضعیت موجودی:
                  <span style="color: darkred"
                    >تنها {{ Number(publish.quantity) }} عدد باقی مانده</span
                  >
                </label>
                <label v-else-if="Number(publish.quantity) <= 0">
                  وضعیت موجودی:
                  <span style="color: #e3572e">ناموجود</span>
                </label>
                <label v-else-if="Number(publish.quantity) >= x">
                  وضعیت موجودی:
                  <span style="color: green">موجود</span>
                </label>

                <div class="input-group align-items-center">
                  <!-- publish -->
                  <label>نوبت چاپ :</label>
                  <select
                    class="form-control select-mr mr-2"
                    v-model="publish"
                    ref="publish"
                    @change="calculatePrice()"
                  >
                    <option
                      v-for="(item, index) in product.dynamicAttributes"
                      :key="'publish' + index"
                      :value="item"
                    >
                      {{ item.publish }}
                    </option>
                  </select>
                  <!-- publish -->

                  <!-- counter -->
                  <label v-if="Number(publish.quantity) > 0">تعداد :</label>
                  <counter
                    ref="counter"
                    v-if="Number(publish.quantity) > 0"
                    :min="product.min_limit ? product.min_limit : 1"
                    :max="
                      product.max_limit &&
                      Number(product.max_limit) < Number(publish.quantity)
                        ? product.max_limit
                        : publish.quantity
                    "
                    :value="product.min_limit ? product.min_limit : 1"
                    @input="count = $event"
                  ></counter>
                  <!-- counter -->
                </div>
                <br />

                <!-- special price -->
                <div
                  v-if="publish.special_price && publish.special_price !== 0"
                >
                  <div class="d-flex">
                    <p class="old-price">
                      {{
                        new Intl.NumberFormat().format(publish.back_cover_price)
                      }}
                      ریال
                    </p>
                    <p class="discount-percent mr-2">
                      <!-- {{
                      publish.special_price > 100
                        ? new Intl.NumberFormat().format(publish.special_price)
                        : publish.special_price + "%"
                    }} -->
                      {{
                        new Intl.NumberFormat().format(
                          (
                            ((Number(publish.back_cover_price) -
                              Number(publish.special_price)) /
                              Number(publish.back_cover_price)) *
                            100
                          ).toFixed(0)
                        ) + "%"
                      }}
                    </p>
                  </div>
                  <p class="new-price">
                    <!-- {{
                    publish.special_price > 100
                      ? new Intl.NumberFormat().format(
                          Number(publish.back_cover_price) -
                            Number(publish.special_price)
                        )
                      : new Intl.NumberFormat().format(
                          (
                            Number(publish.back_cover_price) -
                            (Number(publish.back_cover_price) *
                              Number(publish.special_price)) /
                              100
                          ).toFixed(0)
                        )
                  }} -->
                    {{ new Intl.NumberFormat().format(publish.special_price) }}
                    ریال
                  </p>
                </div>
                <!-- special price -->

                <!-- price -->
                <p v-else class="new-price" v-show="publish">
                  {{ new Intl.NumberFormat().format(publish.back_cover_price) }}
                  ریال
                </p>
                <!-- price -->

                <div>
                  <button
                    v-if="basketLoading"
                    class="submit-btn btn-margin"
                    style="width: 160px"
                  >
                    <Spinner :circle="true"></Spinner>
                  </button>

                  <input
                    v-else-if="publish.quantity > 0"
                    @click="addToCard()"
                    class="submit-btn btn-margin"
                    type="button"
                    style="width: 160px"
                    value="افزودن به سبد خرید"
                  />

                  <!-- Remind -->
                  <RemindAction v-else :productId="product.id"></RemindAction>
                  <!-- Remind -->

                  <!-- <input
                  v-else
                  @click="registerOrder()"
                  class="info-btn btn-margin"
                  type="button"
                  value="سفارش کالا"
                /> -->
                </div>
                <!-- details -->
              </div>
              <!-- details -->
            </div>
          </div>
          <ProductContent
            :product="product"
            :publish="publish"
            :category="breadcrumbs[3] ? breadcrumbs[3] : ''"
          ></ProductContent>
        </div>
        <div v-if="showRelative">
          <RelativeProduct :productid="product.id"></RelativeProduct>
        </div>
      </div>
    </div>
    <div id="myModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body add-fav">
            <img src="/template/images/Heart3.svg" alt="" />
            <p>این کالا به لیست علاقه‌مندی‌ها اضافه شد.</p>
            <input class="add-to-fav" type="button" value="اضافه گردد" />
            <input
              class="see-fav"
              type="button"
              value="مشاهده لیست علاقه‌مندی‌ها"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="deleteFav" class="modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body delete-fav">
            <img src="/template/images/Bucket 24px.svg" alt="" />
            <p>این کالا از لیست علاقه‌مندی‌ها حذف گردد؟</p>
            <input class="delete-from-fav" type="button" value="حذف گردد" />
            <input class="not-delete" type="button" value="خیر" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ProductContent from "@/components/ProductContent.component";
import FavoriteAction from "@/components/FavoriteAction.component";
import RemindAction from "@/components/RemindAction.component";
import Breadcrumb from "@/components/breadcrumb.component";
import counter from "@/components/counter.component";
import RelativeProduct from "@/components/slider/RelativeProduct.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "ProductPage",
  components: {
    Breadcrumb,
    ProductContent,
    FavoriteAction,
    RemindAction,
    counter,
    RelativeProduct,
    Spinner
  },
  data() {
    return {
      loader: true,
      loading: true,
      basketLoading: false,
      breadcrumbs: [],
      productInfo: {},
      mainImage: "",
      selected: {},
      product: {},
      publish: {
        id: "",
        price: ""
      },
      count: 0,
      price: 0,
      x: "",
      showRelative: false
    };
  },
  mounted() {},
  watch: {
    count(newVal) {
      if (this.$refs.counter) {
        this.$refs.counter.count = newVal;
      }
    }
  },
  created() {
    //get product
    this.$store
      .dispatch("getProduct", { slug: this.$route.params.slug })
      .then(result => {
        this.product = result.items;
        this.mainImage = this.product.intro_image;
        if (
          this.product.dynamicAttributes &&
          this.product.dynamicAttributes.length > 0
        ) {
          this.publish = this.product.dynamicAttributes[0];
          this.count = this.product.min_limit ? this.product.min_limit : 1;
        }
        this.x = result.show_qty;
        this.showRelative = true;
        this.breadcrumbs = result.breadcrumb;
        //this.product.qty = 0;//for test
        // this.calculatePrice();
        this.loading = false;
      });
  },
  computed: {
    categoryName() {
      if (this.product.category) {
        return this.product.category.name;
      } else {
        return "محصولات";
      }
    },
    categoryUrl() {
      if (this.product.category) {
        return "/category/" + this.product.category.slug;
      } else {
        return "/search/";
      }
    }
  },
  methods: {
    calculatePrice() {
      this.updateImages();
      this.count = this.product.min_limit ? this.product.min_limit : 1;
      //reset count
      return (
        Number(this.count) *
        Number(
          this.publish.special_price && this.publish.special_price !== 0
            ? this.publish.special_price
            : this.publish.back_cover_price
        )
      );
    },
    updateImages() {
      
      if (this.publish) {
        this.$store
          .dispatch("getProductImages", {
            main_code: this.publish.main_code
          })
          .then(res => {
            this.mainImage = res[0];
            this.product.images = res;
          });
      }
    },
    addToCard() {
      if (!this.publish || !this.publish.id || this.count <= 0) {
        this.$refs.publish.focus();
        return;
      }

      //save in localstorage and update basket
      let selected = {
        image: this.product.intro_image,
        productId: this.product.id,
        name: this.product.name,
        min_limit: this.product.min_limit,
        max_limit: this.product.max_limit,
        quantity: this.count,
        publish: this.publish, //pass all publish
        options: this.publish.id,
        // publish: this.publish,
        publish_quantity: this.publish.quantity,
        price: this.publish.back_cover_price,
        special_price: this.publish.special_price
      };

      this.$store.dispatch("addToBasket", {
        product: selected
      });
      // .then(product => {
      //if user is login save in db
      if (localStorage.getItem("token")) {
        //enable loader
        // this.basketLoading = true;

        this.$store
          .dispatch("addToBasketReq", { product: selected })
          .then(res => {
            if (!res) {
              this.$swal
                .fire({
                  position: "center",
                  icon: "warning",
                  title: "خطا در اضافه کردن محصول",
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  //failed then remove from basket
                  this.$store.dispatch("removeFromBasket", {
                    product: selected
                  });
                });
            }
            //disable loader
            // this.basketLoading = false;
          });
      }
      // });
    },
    registerOrder() {
      let selected = {
        productId: this.product.id,
        attrId: this.publish.id,
        quantity: this.count
      };

      //enable loader
      this.loading = true;

      this.$store
        .dispatch("registerOrder", {
          options: selected
        })
        .then(result => {
          if (result === 401) {
            this.$swal
              .fire({
                position: "center",
                icon: "warning",
                title: "لطفا وارد حساب کاربری خود شوید !",
                showConfirmButton: false,
                timer: 1500
              })
              .then(() => {
                this.$router.push({ name: "auth" });
                return;
              });
          } else if (result === 200) {
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: "سفارش شما با موفقیت ثبت شد",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: "ثبت درخواست با مشکل مواجه شد",
              showConfirmButton: false,
              timer: 1500
            });
          }

          //disable loader
          this.loading = false;
        });
    }
  },
  beforeRouteUpdate(to, from, next) {
    //get product
    this.loading = true;

    //get product
    this.$store
      .dispatch("getProduct", { slug: to.params.slug })
      .then(result => {
        this.product = result.items;
        this.mainImage = this.product.intro_image;
        if (
          this.product.dynamicAttributes &&
          this.product.dynamicAttributes.length > 0
        ) {
          this.publish = this.product.dynamicAttributes[0];
          this.count = this.product.min_limit ? this.product.min_limit : 1;
        }
        this.x = result.show_qty;
        this.showRelative = true;

        //this.product.qty = 0;//for test
        // this.calculatePrice();
        this.loading = false;
      });
    next();
  }
};
</script>
<style scoped>
.add-to-wishlist {
  border: none !important;
  color: #1c403e !important;
  margin-right: auto;
  margin-left: auto;
  font-size: 13px;
}

.details {
  margin-top: 0.5rem;
  display: flex;
  flex-flow: column;
  text-align: right;
  line-height: 2;
  height: 100%;
  min-width: 400px;
  justify-content: space-between;
}

.image-container {
  height: 500px;
  width: 500px;
}

.image-container ul {
  direction: ltr;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0 !important;
}

.image-container ul li {
  padding-right: 5px;
}

.image-container ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.image-container ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.image-container ul::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.image-container .main-img {
  max-width: 400px;
  margin: 0 1rem;
}

.btn-margin {
  margin: 1rem;
}

.image-container ul li img {
  width: 100px;
  margin: 0 auto 0.5rem auto;
  cursor: pointer;
}

.old-price {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #9b9b9b;
  text-decoration-line: line-through;
}

.select-mr {
  margin-left: 1rem;
  flex: 0.5 1 auto !important;
}

.new-price {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
}

.discount-percent {
  height: 25px;
  width: 45px;
  border-radius: 29px;
  background: rgba(255, 68, 118, 1);
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 550px) {
  .image-container-div {
    width: 100%;
  }

  .image-container {
    /* height: 500px; */
    width: 100%;
    flex-flow: column-reverse;
    max-height: 100%;
  }

  .image-container .slides {
    display: flex;
    margin-top: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .image-container ul li {
    display: flex;
  }

  .col-custom {
    width: 100%;
  }

  .details {
    min-width: 100%;
  }
}
</style>
