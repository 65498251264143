<template>
  <div>
    <div v-if="loading" align="center">
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <Breadcrumb
        :currentPageName="postInfo.title"
        :sectionName="'بلاگ'"
        :sectionAddr="'/blog'"
      ></Breadcrumb>
      <div class="container">
        <div class="card">
          <img
            :src="post_img"
            class="card-img-top img_fit"
            :alt="postInfo.title"
          />
          <div class="card-body rtl_mode">
            <h3>{{ postInfo.title }}</h3>
            <p class="card-text rtl_mode" v-html="postInfo.body"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { env } from "../../../enums";
import Breadcrumb from "@/components/breadcrumb.component";

export default {
  name: "post",
  components: {
    Breadcrumb
  },
  data: function() {
    return {
      loading: true,
      wallet: [],
      postInfo: null,
      post_img: ""
    };
  },
  created() {
    this.$store
      .dispatch("getPost", { slug: this.$route.params.slug })
      .then(result => {
        this.postInfo = result;
        this.post_img = env.image_url + this.postInfo.image;
        this.loading = false;
      });
  }
};
</script>
<style scoped>
.rtl_mode {
  direction: rtl;
  text-align: right;
}

.img_fit {
  height: 300px;
  object-fit: scale-down;
}
</style>
