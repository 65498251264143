<template>
  <section class="contact">
    <breadcrumb current-page-name="تماس با ما"></breadcrumb>
    <div class="wrapper">
      <!-- <div v-html="body"> -->

      <div class="col-xl-8 col-md-10 col-10 border mt-5 mb-5">
        <h5 class="text-center mt-3">تماس با پاکت</h5>
        <div class="d-flex flex-wrap">
          <div class="col-md-6 col-sm-6">
            <input class="input" type="text" placeholder="نام" v-model="name" />
          </div>
          <div class="col-md-6 col-sm-6">
            <input
              class="input"
              type="email"
              placeholder="ایمیل"
              v-model="email"
            />
          </div>
        </div>
        <div class="col-xl-12">
          <input
            class="input"
            type="number"
            placeholder="شماره تماس"
            v-model="phone"
          />
        </div>
        <div class="col-xl-12">
          <textarea
            class="input"
            placeholder="پیام شما"
            v-model="message"
          ></textarea>
        </div>
        <div class="col-xl-12">
          <button class="submit-btn mb-3" type="submit" @click="sendMessage">
            ارسال
          </button>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="col-xl-8 col-md-10 col-10 border address-container" v-html="body">
      
        <!-- <ul>
          <li>
            <span class="bolder-span">
              <i class="fa fa-phone"></i>
              اطلاعات تماس :
            </span>
            <ul class="address-details">
              <a href="tel:02176791622" style="color: black !important;">
                <li>76791622(021)</li>
              </a>
            </ul>
            <hr />
          </li>
          <li>
            <span class="bolder-span">
              <i class="fa fa-envelope"></i>
              اطلاعات ایمیل :
            </span>
            <ul class="address-details">
              <li>info@pakatshop.com</li>
            </ul>
            <hr />
          </li>
          <li>
            <span class="bolder-span">
              <i class="fa fa-map-marker"></i>
              نشانی:
            </span>
            <ul class="address-details">
              <li>
                تهران، خیابان دماوند، خیابان اتحاد 11 غربی، پلاک 10
              </li>
            </ul>
          </li>
        </ul> -->
      </div>
    </div>
    <!-- </div> -->
  </section>
</template>
<script>
import Breadcrumb from "../../components/breadcrumb.component";
export default {
  data() {
    return {
      name: null,
      email: null,
      message: null,
      phone: null,
      title: "",
      body: ""
    };
  },
  components: { Breadcrumb },
  methods: {
    sendMessage: function() {
      if (
        this.name == null ||
        this.email == null ||
        this.message == null ||
        this.phone == null
      ) {
        this.$swal.fire({
          position: "top-end",
          icon: "error",
          title: "لطفا تمامی فیلد ها را پر کنید !",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        this.$store
          .dispatch("saveContact", {
            data: {
              fullname: this.name,
              email: this.email,
              description: this.message,
              phone: this.phone
            }
          })
          .then(result => {
            if (result.success) {
              this.name = "";
              this.email = "";
              this.message = "";
              this.phone = "";
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "با موفقیت ثبت گردید !",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              this.$swal.fire({
                position: "top-end",
                icon: "error",
                title:
                  "مشکلی در ثبت پیغام شما رخ داده است لطفا دوباره تلاش کنید !",
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
      }
    }
  },
  created() {
    //get details from backend
    this.$store.dispatch("getDetails", { slug: "contact-us" }).then(result => {
      if (result) {
        this.title = result.title;
        this.body = result.body;
      }
    });
  }
};
</script>
<style scoped>
textarea.input {
  min-height: 100px;
}
.address-container ul {
  list-style: none;
}
.address-container {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 1rem;
  text-align: right;
  line-height: 2;
  height: 100%;
  margin: auto;
}
.address-details {
  margin-top: 1rem;
  margin-right: 5rem;
}
.bolder-span {
  font-size: 16px !important;
}
.address-details li {
  display: flex;
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.address-details li::before {
  display: none;
}
.social-media {
  display: flex;
}
.social-media li {
  font-size: 25px;
  margin: auto 1rem;
}
</style>
