<template>
  <section>
    <Breadcrumb currentPageName="سبد خرید شما"></Breadcrumb>
    <div class="container-fluid body">
      <div class="row">
        <div class="col-xl-12" style="text-align: center">
          <img src="/template/images/success.svg" class="m-3" alt="" />
          <p id="first-p">تبریک</p>
          <p>خرید شما با موفقیت انجام شد</p>
          <div
            class="d-flex flex-wrap justify-content-center align-content-center"
          >
            <router-link
              tag="input"
              class="submit-btn m-2"
              type="button"
              value="خرید مجدد "
              to="/"
            ></router-link>
            <router-link
              tag="input"
              class="info-btn m-2"
              type="button"
              value="مشاهده‌ی سفارش های من"
              to="/profile/orders"
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";

export default {
  components: { Breadcrumb },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("getBasketReq");
  }
};
</script>
