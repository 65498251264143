<template>
  <div class="m-5">
    <h4 class="text-right mb-3">پیشنهادها</h4>

    <VueSlickCarousel
      class="custom"
      ref="SliderTop"
      :asNavFor="$refs.SliderBottom"
      :slidesToShow="3"
      :focusOnSelect="false"
      :draggable="false"
    >
      <div
        v-for="(product, index) in products"
        :key="product[index]"
        align="center"
      >
        <img
          :src="
            product.intro_image && product.intro_image.length !== 0
              ? product.intro_image
              : '/template/images/no-image.png'
          "
          @error="product.intro_image = '/template/images/no-image.png'"
          alt=""
          class="slide_img"
        />
      </div>
    </VueSlickCarousel>

    <VueSlickCarousel
      ref="SliderBottom"
      :asNavFor="$refs.SliderTop"
      v-bind="settings"
      :slidesToShow="1"
      :initialSlide="1"
      :focusOnSelect="true"
    >
      <div
        v-for="(product_sub, index) in products"
        :key="product_sub[index]"
        align="center"
      >
        <b>{{ product_sub.name }}</b>
        <router-link tag="a" :to="'/product/' + product_sub.slug">
          <div class="buy_btn">مشاهده</div>
        </router-link>
      </div>
    </VueSlickCarousel>
    <div @click="NextSlide" class="NextSlide">
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </div>
    <div @click="BackSlide" class="BackSlide">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "selectedProducts",
  props: ["products"],
  data: function() {
    return {
      loading: true,
      settings: {
        arrows: false,
        dots: false,
        focusOnSelect: true,
        draggable: false
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    NextSlide() {
      this.$refs.SliderTop.next();
      this.$refs.SliderBottom.next();
    },
    BackSlide() {
      this.$refs.SliderTop.prev();
      this.$refs.SliderBottom.prev();
    }
  },
  created() {
    /*
    this.$store.dispatch("getFavoriteSlide").then((result) => {
      this.products = result;
      this.loading = false;
    });
    */
  },
  components: {
    VueSlickCarousel
  }
};
</script>

<style scoped>
.slide_img {
  width: 85%;
  height: 300px;
  object-fit: contain;
}
@media screen and (max-width: 480px) {
  .slide_img {
    height: 130px;
  }
}

.NextSlide {
  z-index: 100;
  right: 50px;
  position: absolute;
  font-size: 40px;
  margin-top: -74px;
  cursor: pointer;
}
.BackSlide {
  z-index: 100;
  left: 50px;
  position: absolute;
  font-size: 40px;
  margin-top: -74px;
  cursor: pointer;
}

.buy_btn {
  border: 2px solid #007bff;
  color: #007bff;
  margin: 17px;
  border-radius: 4px;
  cursor: pointer;
  width: 101px;
  bottom: 0;
}
</style>
