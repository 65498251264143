<template>
  <div v-if="loading" align="center" style="margin: 50px">
    <div class="spinner-grow spinner-grow-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div v-else>
    <div v-if="products.length > 0">
      <ItemsCarousel
        title="محصولات مشابه"
        slug=""
        :products="products"
      ></ItemsCarousel>
    </div>
  </div>
</template>
<script>
import ItemsCarousel from "@/components/ItemsCarousel.component";

export default {
  name: "relativeproduct",
  props: ["productid"],
  data: function() {
    return {
      products: [],
      loading: true
    };
  },
  watch: {
    productid: function(newVal) {
      this.loading = true;

      this.$store.dispatch("getRelativeProduct", newVal).then(result => {
        this.products = result;
        this.loading = false;
      });
    }
  },
  created() {
    this.$store.dispatch("getRelativeProduct", this.productid).then(result => {
      this.products = result;
      this.loading = false;
    });
  },
  components: {
    ItemsCarousel
  }
};
</script>
