var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home"},[(_vm.loading)?_c('Spinner',{staticClass:"mt-5 mb-5",attrs:{"circle":true}}):_c('div',[(_vm.slides && _vm.slides.length)?_c('Slider',{attrs:{"items":_vm.slides}}):_vm._e(),(_vm.homepage.Categories && _vm.homepage.Categories.length !== 0)?_c('Category',{attrs:{"categories":_vm.homepage.Categories}}):_vm._e()],1),(_vm.settings.amazingProducts)?_c('AmazingProducts'):_vm._e(),(_vm.settings.boxProducts)?_c('BoxProducts'):_vm._e(),(_vm.settings.latestProducts)?_c('LatestProduct'):_vm._e(),(_vm.loading)?_c('Spinner',{staticClass:"mt-5 mb-5",attrs:{"circle":true}}):_c('div',[(_vm.homepage.banner01_url)?_c('div',[_c('a',{attrs:{"href":_vm.homepage.banner01_url}},[_c('img',{staticClass:"img-responsive",staticStyle:{"width":"100%","max-width":"100%","height":"auto","position":"relative"},attrs:{"src":_vm.homepage.banner01}})])]):_vm._e(),(
        _vm.settings.favoriteProducts &&
          _vm.homepage.Favorites &&
          _vm.homepage.Favorites.length !== 0
      )?_c('FavoriteSlider',{attrs:{"products":_vm.homepage.Favorites}}):_vm._e(),(_vm.homepage.banner02)?_c('div',{staticClass:"mt-5"},[_c('a',{attrs:{"href":_vm.homepage.banner02_url}},[_c('img',{staticClass:"img-responsive",staticStyle:{"width":"100%","max-width":"100%","height":"auto","position":"relative"},attrs:{"src":_vm.homepage.banner02}})])]):_vm._e(),(
        _vm.settings.bestSaleProducts &&
          _vm.homepage.BestSales &&
          _vm.homepage.BestSales.length !== 0
      )?_c('BestSell',{attrs:{"products":_vm.homepage.BestSales}}):_vm._e(),(
        _vm.settings.selectedProducts &&
          _vm.homepage.SelectiveProducts &&
          _vm.homepage.SelectiveProducts.length !== 0
      )?_c('SelectedProducts',{attrs:{"products":_vm.homepage.SelectiveProducts}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }