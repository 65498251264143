<template>
  <section>
    <Breadcrumb
      current-page-name="کیف پول"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    >
    </Breadcrumb>
    <div class="custom-container pb-3 pt-3 ">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <div class="col-lg-8 counter-b">
          <div align="center" style="margin-top: 30px">
            <div>
              <i class="fa fa-money" aria-hidden="true"></i>
              <label style="margin-right: 5px">اعتبار:</label>
              <div v-if="loadingBalance" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>

              <b v-else>
                {{ new Intl.NumberFormat().format(wallet.amount) }} ریال</b
              >
            </div>
          </div>
          <hr />
          <!-- wallet-box -->
          <div class="row customer-panel-box">
            <div v-if="alert.status == true" :class="alert.class">{{alert.message}}</div>
            <div class="col-md-7" align="center">
              <label>مقدار اعتبار را به ریال وارد نمایید</label><br />
              <div>
                <div class="price-btn" @click="setAmount(500000)">
                  {{ new Intl.NumberFormat().format(500000) }} ریال
                </div>
                <div class="price-btn" @click="setAmount(1000000)">
                  {{ new Intl.NumberFormat().format(1000000) }} ریال
                </div>
                <div class="price-btn" @click="setAmount(2000000)">
                  {{ new Intl.NumberFormat().format(2000000) }} ریال
                </div>
              </div>
              <input
                v-model="depositAmount"
                class="input input_small"
                placeholder="مقدار اعتبار به ریال"
              /><br />
              <button @click="Newdeposit()" class="submit-btn">
                <div
                  v-if="loadingTransaction"
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>افزایش اعتبار</span>
              </button>
            </div>
            <div class="col-md-5 border-right">
              <div class="form-group text-right">
                <label for="">کد شارژ هدیه</label>
                <input v-model="giftChargeCode" placeholder="کد خود را وارد نمایید" type="text" class="form-control">
              </div>
              <div class="form-group text-center">
                <button @click="applyGiftCharge" class="submit-btn">
                  <div
                  v-if="loadingTransaction"
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>بررسی و اعمال شارژ</span>
                </button>
              </div>
            </div>
          </div>
          <!-- wallet-box -->
          <div align="center" class="history_title">
            <b>آخرین تراکنش‌ها</b>
          </div>
          <div align="center">
            <div
              v-if="loading"
              class="spinner-border"
              style="margin-top: 20px"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="list">
            <div
              v-for="transaction in History"
              :key="transaction.id"
              class="row_transaction"
            >
              <b>{{ transaction.id }}#</b>
              <br />
              <label>{{ transaction.amount }} ریال</label><br />
              <label>{{ transaction.description }}</label
              ><br />
              <label
                ><span>وضعیت پرداخت :</span>
                {{ checkStatus(transaction.status) }}</label
              ><br />
              <label>
                تاریخ:
                {{
                  transaction.created_at | moment("jYY-jMM-jDD HH:mm")
                }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* wallet-box */

label {
  margin-bottom: 1.5rem !important;
}

/* wallet-box */
.history_title {
  margin-top: 40px;
  background: #616161;
  color: white;
  padding: 10px;
}

.row_transaction {
  text-align: right;
  box-shadow: 1px 0px 7px -3px #000000;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.input_small {
  width: unset;
  text-align: center;
  font-size: 20px;
}

.price-btn {
  border: 1px solid #d3dbe1;
  background-color: white;
  padding: 5px;
  margin: 10px;
  display: inline;
  border-radius: 12px;
  cursor: pointer;
}

.counter-b {
  margin-left: auto;
  margin-right: auto;
}

.router-link-active {
  color: white !important;
}

@media (max-width: 767px) {
  .slide-bar-left {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
</style>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";

export default {
  name: "Wallet",
  data() {
    return {
      wallet: [],
      History: null,
      depositAmount: 100000,
      loading: true,
      loadingBalance: true,
      loadingTransaction: false,
      giftChargeCode:"",
      alert:{
        message:"",
        status:'',
        class:''
      }
    };
  },
  components: { Breadcrumb, SideMenu },
  beforeCreate() {},
  mounted() {},
  created() {
    this.$store.dispatch("getWalletBalance").then(result => {
      this.wallet = result.wallet;
      this.loadingBalance = false;
    });
    this.$store.dispatch("getWalletHistory").then(result => {
      this.History = result.transaction;
      this.loading = false;
    });
  },
  methods: {
    applyGiftCharge(){
      if(this.giftChargeCode != ""){
        this.loadingTransaction = true;
          this.$store
          .dispatch("giftCharge", { code: this.giftChargeCode })
          .then(result => {
            this.loadingTransaction = false;
            if (result.status === "ok") {
              this.alert = {
                message: "شارژ هدیه با موفقیت اعمال گردید !",
                status: true,
                class:"col-md-12 alert alert-success text-right"
              };
            } else {
              this.alert = {
                message: "کد هدیه شما مورد تایید نمی باشد !",
                status: true,
                class:"col-md-12 alert alert-danger text-right"
              };
            }
          });
      }
    },
    setAmount(newAmount) {
      this.depositAmount = newAmount;
    },
    Newdeposit() {
      if (this.depositAmount >= 10000) {
        this.loadingTransaction = true;
        //rial convert to toman
        this.$store
          .dispatch("Newdeposit", { amount: this.depositAmount / 10 })
          .then(result => {
            this.loadingTransaction = false;
            if (result.status === "ok") {
              window.location.href = result.url;
            }
          });
      } else {
        //error
      }
    },
    checkStatus(state) {
      if (state === "complete") {
        return "تراکنش موفق";
      } else if (state === "error") {
        return "تراکنش ناموفق";
      } else if (state === "pending") {
        return "در انتظار پرداخت";
      } else {
        return state;
      }
    }
  }
};
</script>
