<template>
  <div class="search" ref="searchBox" v-clickOutSide="hideSuggestions">
    <input
      class="search-input"
      @focusin="showSuggestions"
      :placeholder="placeholder"
      v-model="input"
      @keypress="showList"
    />
    <i class="fa fa-search icon-search"></i>
    <ul
      class="search-body"
      v-if="suggestions"
      @click="suggestions = !suggestions"
    >
      <li v-for="item in items" :key="item.id">
        <router-link
          tag="a"
          value="مشاهده جزئیات محصول"
          type="button"
          :to="'/product/' + item.slug"
        >
          <img
            :src="item.intro_image"
            @error="item.intro_image = '/template/images/no-image.png'"
          />
          <span>
            {{ item.name }}
          </span>
          <span> {{ new Intl.NumberFormat().format(item.price) }} ریال </span>
        </router-link>
      </li>
      <li v-if="moreRes">
        <span class="more" @click="goToAdvancedSearch">
          جست‌وجوی پیشرفته
          <hr />
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["placeholder", "moreRes"],
  data() {
    return {
      suggestions: false,
      items: [],
      input: "",
      headers: {
        "Content-Type": "application/json"
      }
    };
  },
  directives: {
    clickOutSide: {
      bind: function(el, binding, vnode) {
        el.clickOutSideEvent = function(event) {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutSideEvent);
        document.body.addEventListener("touchstart", el.clickOutSideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutSideEvent);
        document.body.removeEventListener("touchstart", el.clickOutSideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      }
    }
  },
  watch: {
    input: function() {
      if (this.input.length === 0) {
        this.items = [];
      } else if (this.input.length >= 3) {
        //get suggestions
        this.$store
          .dispatch("getSuggestedProducts", {
            q: this.input
          })
          .then(result => {
            this.items = result;
          });
      }
    },
    $route() {
      this.input = "";
    }
  },
  methods: {
    showSuggestions() {
      this.suggestions = true;
    },
    hideSuggestions() {
      this.suggestions = false;
    },
    showList(e) {
      if (e.keyCode === 13) {
        //go to search page
        this.$router.push({ name: "search", query: { q: this.input } });
      }
    },
    goToAdvancedSearch() {
      //go to search page
      this.$router.push({ name: "search", query: { q: this.input } });
    }
  }
};
</script>

<style scoped>
::placeholder {
  color: #6c757d;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d;
}
.search {
  margin: 0.5rem 0;
  width: 100%;
  position: relative;
}

.search-input {
  padding: 5px 10px;
  text-align: right;
  width: 100%;
  height: auto;
  /*min-width: 240px;*/
  border: 0.5px solid #000;
}

.search-body {
  z-index: 100;
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -5px;
  text-align: right;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.search-body li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e7e7e7;
}
.search-body li a {
  font-size: 12px;
  display: flex;
}
.search-body li a span {
  margin: auto 5px;
}
.search-body li:hover,
.search-body li > a:active {
  padding: 10px;
}

.search-body img {
  width: 40px;
  margin-left: 0.5rem;
}

.search .icon-search {
  position: absolute;
  left: 0;
  color: white;
  padding: 0.6rem 1.2rem;
  background-color: #1c403e;
}

.p-relative {
  position: relative;
}

/* show more */
.search .more {
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
  color: #000 !important;
}
.search .more:hover {
  color: #000000 !important;
}

.search .more hr {
  visibility: hidden;
  margin: 5px auto !important;
  border-color: black;
}
.search .more:hover hr {
  visibility: visible;
  animation: showProductAnim 0.5s forwards;
}

@keyframes showProductAnim {
  from {
    width: 0px;
  }
  to {
    width: 110px;
  }
}
</style>
