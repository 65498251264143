<template>
  <div class="container">
    <Spinner v-if="loading"></Spinner>
    <div v-else class="card">
      <div class="card-header">
        <table class="table">
          <tbody>
            <tr>
              <td class="center" style="width: 25%">
                <p class="text-rtl"> شماره فاکتور: {{order.id}}</p>
                <p class="text-rtl">
                  تاریخ فاکتور: {{ order.created_at | moment("jYY/jMM/jDD") }}
                </p>
                <p class="text-rtl">وضعیت سفارش: {{ getStatus }}</p>
              </td>
              <td class="center">
                <p>
                  <img
                    src="../../../../public/img/logo.png"
                    alt="logo"
                    width="70px"
                  />
                </p>
                <h3>فروشگاه اینترنتی پاکت</h3>
                <p>www.pakatshop.com</p>
              </td>
              <td class="center" style="width: 25%">
                <p class="text-rtl"><span>ارسال فاكتور:</span>
                      <span v-if="order.isgift">بله</span>
                      <span v-if="!order.isgift">خیر</span>
                </p>
                <p class="text-rtl"><span>هدیه:</span>
                      <span v-if="order.isfactorsend">بله</span>
                      <span v-if="!order.isfactorsend">خیر</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-header">
        <table class="table">
          <!-- <tbody>
            <tr>
              <td colspan="3">
                <p class="text-rtl">
                  {{ order.shipping_address }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="text-rtl">تلفن: {{ order.shipping_phone }}</p>
              </td>
              <td>
                <p class="text-rtl">
                  کد پستی: {{ order.shipping_postal_code }}
                </p>
              </td>
              <td>
                <p class="text-rtl">نام خریدار: {{ order.fullname }}</p>
              </td>
            </tr>
          </tbody> -->
          <tbody>

                <tr>
                    <td>
                        <p class="text-rtl">شماره همراه گيرنده: {{order.shipping_phone}}</p>
                    </td>
                    <td>
                        <p class="text-rtl">نام گیرنده: {{ order.shipping_fullname }}</p>
                    </td>

                    <td>
                        <p class="text-rtl">شماره همراه خريدار: {{order.phone}}</p>
                    </td>
                    <td>
                        <p class="text-rtl">نام خریدار: {{ order.fullname }}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <p class="text-rtl">
                            کد پستی: {{order.shipping_postal_code}}
                        </p>
                    </td>
                    <td colspan="2">
                        <p class="text-rtl">
                            <span>آدرس: </span>

                            <span>{{order.shipping_province}}</span> ,
                            <span>{{order.shipping_city}}</span> ,
                            <span>{{order.shipping_address}}</span>
                        </p>
                    </td>

                </tr>
                </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="row mb-4">
          <table class="table">
            <tbody>
              <tr>
                <td class="text-center" style="width: 5%">
                  <p>ردیف</p>
                </td>
                <td class="text-center">
                  <p>کد کالا</p>
                </td>
                <td class="text-center" style="width: 40%">
                  <p>نام محصول</p>
                </td>
                <td class="text-center">
                  <p>فروشنده</p>
                </td>
                <td class="text-center">
                  <p>قیمت</p>
                </td>
                <td class="text-center">
                  <p>تعداد</p>
                </td>
                <td class="text-center">
                  <p>تخفیف (درصد)</p>
                </td>
                <td class="text-center">
                  <p>مبلغ کل (ریال)</p>
                </td>
              </tr>
              <tr v-for="(product, index) in products" :key="index">
                <td class="text-center">
                  <p>{{ index + 1 }}</p>
                </td>
                <td class="text-center">{{ product.main_code }}</td>
                <td class="center">{{ product.name }}</td>
                <td class="center">{{ product.brand }}</td>
                <td class="text-center">
                  {{ new Intl.NumberFormat().format(product.price) }}
                </td>
                <td class="text-center">{{ product.qty }}</td>
                <td class="text-center">
                  {{
                    product.special_price &&
                    product.special_price !== product.price
                      ? new Intl.NumberFormat().format(
                          (
                            ((product.price - product.special_price) /
                              product.price) *
                            100
                          ).toFixed(0)
                        ) + "%"
                      : 0
                  }}
                </td>
                <td class="text-center">
                  {{
                    new Intl.NumberFormat().format(
                      Number(product.qty) * Number(product.price)
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          
          <div class="col-6 " style="padding-right: 0">
                    <table style="height: 100%;width: 106%;">
                        <tbody>
                        <tr>
                            <td>توضیحات:
                                <p>{{order.description}}</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-6" style="padding-left: 0">
                    <table class="table">
                        <tbody>
                        <tr>
                            <td class="center text-center">جمع ناخالص</td>
                            <td class="center text-center">
                                {{ new Intl.NumberFormat().format(invoice.sumBackcoverPrice) }}  ریال
                            </td>
                        </tr>
                        <tr>
                            <td class="center text-center">درصد كد تخفيف</td>
                            <td class="center text-center">
                                    {{invoice.discount + "%"}}
                            </td>
                        </tr>
                        <tr>
                            <td class="center text-center">مبلغ تخفیف فاکتور</td>
                            <td class="center text-center">
                                {{ new Intl.NumberFormat().format(invoice.sumBackcoverPrice - invoice.total_clean) }} ریال
                            </td>
                        </tr>


                        <tr>
                            <td class="center text-center">هزینه‌ی ارسال</td>
                            <td class="text-center">
                               {{new Intl.NumberFormat().format(order.shipping_price)}}
                            </td>
                        </tr>
                        <tr>
                            <td class="center text-center">جمع کسورات و اضافات فاکتور</td>
                            <td class="center text-center">{{ new Intl.NumberFormat().format(invoice.addAndSub)  }} ریال </td>
                        </tr>
                        <tr>
                            <td class="center  text-center">جمع خالص دریافتی فاکتور</td>
                            <td class="center  text-center">
                                {{ new Intl.NumberFormat().format(order.total_price) }}  ریال
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
          <!-- <table class="table" style="margin-top: 1rem">
            <tbody>
              <tr>
                <td
                  rowspan="5"
                  class="center"
                  style="width: 60%; border: none !important"
                ></td>
                <td class="center">مبلغ تخفیف فاکتور</td>
                <td colspan="2" class="center">
                  {{
                    order.discount && order.discount >= 100
                      ? order.discount + "ریال"
                      : order.discount
                      ? order.discount + "%"
                      : "0"
                  }}
                </td>
              </tr>
              <tr>
                <td class="center">جمع خالص فاکتور</td>
                <td colspan="2" class="center">
                  {{ new Intl.NumberFormat().format(price().total) }} ریال
                </td>
              </tr>
              <tr>
                <td class="center">جمع کسورات و اضافات فاکتور</td>
                <td colspan="2" class="center">0</td>
              </tr>
              <tr>
                <td class="center">هزینه ی ارسال</td>
                <td colspan="2" class="text-center">
                  {{
                    new Intl.NumberFormat().format(
                      order.get_shipping ? order.get_shipping.price : 0
                    )
                  }}
                  ریال
                </td>
              </tr>
              <tr>
                <td class="center">جمع خالص دریافتی فاکتور</td>
                <td colspan="2" class="center">
                  {{ new Intl.NumberFormat().format(order.total_price) }} ریال
                </td>
              </tr>
            </tbody>
          </table> -->

        </div>
      </div>
    </div>
    <button id="printPageButton" class="submit-btn" onclick="window.print();">
      چاپ فاکتور
    </button>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "print",
  components: { Spinner },
  data() {
    return {
      order: {},
      products: [],
      invoice: [],
      shipping_name: "",
      store_name: "",
      loading: false
    };
  },
  created() {
    this.loading = true;

    this.$store
      .dispatch("getOrderInfo", { orderId: this.$route.params.id })
      .then(result => {
        this.order = result.order;
        this.products = result.products;
        this.invoice = result.invoice;
        this.shipping_name = result.shipping_name;
        this.store_name = result.store_name;
        this.loading = false;
      });
  },
  computed: {
    getStatus() {
      let res = "";

      switch (this.order.status) {
        case "primary":
          res = "ثبت اولیه";
          break;
        case "delivery":
          res = "در حال ارسال";
          break;
        case "lost":
          res = "لغو سفارش";
          break;
        case "accept":
          res = "تایید سفارش";
          break;
        case "delivered":
          res = "تحویل داده شده";
          break;
        case "returned":
          res = "مرجوع";
          break;
        case "accept_returned":
          res = "تایید مرجوعی";
          break;
        case "reject_returened":
          res = "لغو مرجوعی";
          break;
        default: //do nothing
      }
      return res;
    }
  },
  methods: {
    count() {
      let total = 0;

      this.products.forEach(item => {
        total += item.qty;
      });

      return { total };
    },
    price() {
      let total = 0;

      this.products.forEach(item => {
        total += Number(item.price) * Number(item.qty);
      });

      return { total };
    },
    discountCalc() {
      let total = 0;

      this.products.forEach(
        item => (total += Number(item.price) * Number(item.quantity))
      );

      let special_prices = 0;

      this.products.forEach(
        item =>
          (special_prices +=
            item.special_price && item.special_price != 0
              ? Number(item.special_price) * Number(item.quantity)
              : 0)
      );

      let res = (
        ((Number(total) - Number(special_prices)) / Number(total)) *
        100
      ).toFixed(0);
      return { res };
    }
  }
};
</script>
<style scoped>
.container {
  display: grid;
  justify-content: center;
  padding: 3rem;
}
.text-rtl {
  font-family: BNazanin !important;

  text-align: right;
}
.text-center {
  font-family: BNazanin !important;

  text-align: center;
  vertical-align: inherit;
}
.text-left {
  text-align: left;
}

input[type="checkbox"] {
  transform: scale(2);
  margin: 0.5rem;
}

.card {
  border: none !important;
}
.table {
  margin-bottom: 0px;
}
.table p {
  font-family: BNazanin !important;
  margin: 0 !important;
}
.card-header {
  text-align: center;
  border: 1px solid black;
  background-color: #fff;
}

.card .card-body {
  direction: rtl;
  text-align: right;
}
.card-body {
  padding: 1rem;
  padding-top: 0 !important;
}
#printPageButton {
  margin: 2rem auto;
  width: 150px;
}

.table td,
.table th {
  font-family: BNazanin !important;

  border: none;
}
.card-body table td {
  border: 1px solid black;
}
</style>
