<template>
  <!-- mega menu -->
  <div class="d-none mega-menu d-lg-flex align-items-center flex-wrap">
    <div class="menu-item" v-for="(item, i) in items" :key="i">
      <a
        v-if="item.children.length === 0"
        :href="item.url && item.url !== '' ? item.url : '#'"
      >
        {{ item.title }}
      </a>
      <div v-else class="custom-dropdown">
        <button class="dropbtn">
          <a :href="item.url && item.url !== '' ? item.url : '#'">
            {{ item.title }}
            <i class="fa fa-caret-down"></i>
          </a>
        </button>

        <div class="mega-dropdown-content">
          <!-- parents -->
          <ul class="tab parent-list">
            <li
              class="tablinks"
              v-for="(parent, j) in item.children"
              :key="j"
              @mouseover="openMenuItem($event, 'child' + parent.id + j)"
              v-bind:class="{ active: j === 0 }"
            >
              <a :href="parent.url && parent.url !== '' ? parent.url : '#'">
                <b>{{ parent.title }}</b>
              </a>
            </li>
          </ul>
          <!-- parents -->

          <!-- childs -->
          <ul
            class="child-list tabcontent"
            v-for="(parent, j) in item.children"
            :key="j"
            :id="'child' + parent.id + j"
            v-bind:class="{
              active: j === 0,
              has_img: parent.children.length === 0
            }"
          >
            <img
              v-if="parent.children.length === 0 && parent.image"
              :src="parent.image"
              :alt="parent.title"
              :title="parent.title"
              @error="
                '/template/images/no-image.png';


              "
            />
            <li v-else>
              <a
                v-for="(child, k) in parent.children"
                :key="k"
                :href="child.url && child.url !== '' ? child.url : '#'"
              >
                {{ child.title }}
              </a>
            </li>
          </ul>
          <!-- childs -->
        </div>
      </div>
    </div>
  </div>
  <!-- mega menu -->
</template>

<script>
export default {
  name: "MegaMenu",
  props: ["items"],
  data() {
    return {};
  },
  methods: {
    openMenuItem(evt, itemId) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(itemId).style.display = "block";
      evt.currentTarget.className += " active";
    }
  }
};
</script>
<style scoped>
a {
  color: #000 !important;
}
a:hover,
a.active {
  color: #1c403e !important;
}
.mega-menu {
  padding: 0 3rem;
  align-items: center;
  justify-content: center;
}
.mega-menu .menu-item {
  padding: 0 0.75rem;
}

.custom-dropdown {
  width: max-content;
}

.custom-dropdown .dropbtn {
  font-size: 14px;
  border: none;
  outline: none;
  color: #000;
  padding: 14px 16px;
  background-color: inherit;
  /*font: inherit;*/
  margin: 0;
}

@media (max-width: 1030px) {
  .custom-dropdown .dropbtn {
    font-size: 12px !important;
    padding: 14px 10px !important;
  }
}
.mega-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 10px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 50%);
  /* width: max-content; */
  z-index: 1000;
  padding: 1rem 0;
  right: 15%;
  width: 70%;
  /* top: 140px; */
  padding-top: 2rem;
  /* padding-bottom: 2rem; */
}
@media screen and (max-width: 1500px) {
  .mega-dropdown-content {
    right: 5%;
    width: 90%;
  }
  .mega-menu {
    padding: 0 1rem;
  }
}
.custom-dropdown:hover .mega-dropdown-content {
  display: flex;
  /* max-height: 400px;
    overflow-y:auto ; */
  /* flex-wrap: wrap; */
}

.parent-list {
  display: flex;
  list-style: none;
  font-size: 15px;
  text-align: right;
  flex-flow: column;
  line-height: 3;
  border-left: 1px solid #e7e7e7;
  /* width: max-content; */
  height: 100%;
  max-height: 400px;
  overflow-y: auto;
  max-width: 300px;
  margin-bottom: 0 !important;
}
/* scrollbar */
.parent-list::-webkit-scrollbar {
  width: 5px;
}

.parent-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.parent-list::-webkit-scrollbar-thumb {
  background-color: #1c403e !important;
  /* outline: 1px solid slategrey; */
}
/* scrollbar */

.parent-list li {
  cursor: pointer;
  padding: 0 2rem;
  font-size: 13px;
}
.parent-list li:hover,
.parent-list li.active {
  color: #1c403e !important;
}
.child-list {
  display: none;
  list-style-type: none;
  height: inherit;
  max-height: 400px;
  column-fill: auto;
  -webkit-column-fill: auto;
  -moz-column-fill: auto;
  column-width: 100%;
  -webkit-column-width: 150px;
  -moz-column-width: 150px;
  column-gap: 50px;
  margin-bottom: 0 !important;
}
.child-list li {
  text-align: right;
  line-height: 2.5;
  padding-right: 1rem;
}
.child-list.active {
  display: block;
}
.child-list:first-child {
  display: block;
}
.child-list a {
  text-align: right;
  font-size: 15px;
  width: 100%;
  display: block;
}
.tablinks.active {
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  background-color: #e7e7e7;
}
/* has imag */
ul.child-list.has_img {
  width: 100%;
  position: relative;
}
ul.child-list.has_img img {
  position: absolute;
  left: 1rem;
  bottom: 0;
  height: 100%;
  max-width: 100%;
}
</style>
