<template>
  <div>
    <div v-if="leanier" class="leanier">
      <div class="spinner-grow text-secondary ml-2 mr-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary ml-2 mr-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary ml-2 mr-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center ml-auto mr-auto"
    >
      <button
        v-if="circle"
        class="loading-spinner circle-spinner"
        type="button"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
      <button v-else class="loading-spinner" type="button" disabled>
        ... منتظر باشید

        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "spinner",
  props: ["circle", "leanier"]
};
</script>
<style scoped>
.loading-spinner .spinner-border-sm {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}
.loading-spinner {
  background-color: transparent;
  border: none;
  color: black;
  direction: ltr;
  margin: 5rem;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.loading-spinner.circle-spinner .spinner-border-sm {
  width: 25px;
  height: 25px;
}
.loading-spinner.circle-spinner {
  color: #007bff;
  margin: 0 !important;
}
.leanier {
  left: calc(50% - 72px);
  position: absolute;
  top: calc(50% - 16px);
}
.submit-btn .loading-spinner.circle-spinner {
  color: #fff !important;
}
</style>
