<template>
  <section>
    <Breadcrumb
      current-page-name="دیدگاه‌های من"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>

    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <Spinner v-if="loading"></Spinner>
        <div
          v-else-if="!comments || comments.length === 0"
          class="col-lg-8 d-flex flex-column align-items-center ml-auto mr-auto mt-5"
        >
          <div style="margin-bottom: 18px">
            <img src="/template/images/empty-comment.svg" alt="" />
          </div>
          <div style="margin-bottom: 20px">
            هیچ دیدگاه ثبت شده ای وجود ندارد.
          </div>
        </div>
        <div v-else class="col-xl-8 col-md-9 col-sm-9 customer-panel-box">
          <div class="custom-table-container">
            <table class="custom-table">
              <thead>
                <tr class="h">
                  <th>تصویر</th>
                  <th>عنوان</th>
                  <th>ناشر</th>
                  <th>عنوان دیدگاه</th>
                  <th>متن دیدگاه</th>
                  <th>تاریخ ثبت</th>
                  <th>وضعیت</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(comment, index) in comments" :key="index">
                  <td>
                    <img
                      width="50px"
                      :src="
                        comment.get_product ? comment.get_product.image : ''
                      "
                      alt="img-product"
                    />
                  </td>
                  <td>
                    <router-link
                      v-if="comment.get_product"
                      tag="a"
                      value="مشاهده جزئیات محصول"
                      type="button"
                      :to="'/product/' + comment.get_product.slug"
                      >{{ comment.get_product.name }}</router-link
                    >
                  </td>
                  <td>
                    {{ comment.get_product ? comment.get_product.brand : "" }}
                  </td>
                  <td>{{ comment.title }}</td>
                  <td>
                    <p class="m-0">{{ comment.description }}</p>
                  </td>
                  <td>
                    {{ comment.created_at | moment("HH:mm jYY/jMM/jDD") }}
                  </td>
                  <td class="">
                    <span
                      v-if="comment.status === 1"
                      class="tag"
                      style="background-color: green"
                      >تایید شده</span
                    >
                    <span v-else class="tag" style="background-color: red"
                      >تایید نشده</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";

export default {
  name: "profile-order-show",
  data() {
    return {
      loading: false,
      comments: []
    };
  },
  components: { SideMenu, Breadcrumb, Spinner },
  methods: {},
  created() {
    this.loading = true;

    this.$store.dispatch("getComments").then(result => {
      // this.order = result.order;
      this.comments = result;
      // this.shipping_name = result.shipping_name;
      this.loading = false;
    });
  }
};
</script>

<style scoped>
.tag {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}
</style>
