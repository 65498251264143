<template>
  <section class="category">
    <div class="col-12">
      <!-- product list -->
      <Spinner v-if="loading"></Spinner>

      <items-grid v-else :products="products" type="grid"></items-grid>
      <!-- product list -->
    </div>
  </section>
</template>

<script>
import Spinner from "../../components/Spinner.component";
import ItemsGrid from "../../components/ItemsGrid.component";

export default {
  name: "ProductSelective",
  data() {
    return {
      loading: true,
      products: []
    };
  },
  components: {
    Spinner,
    ItemsGrid
  },
  created() {
    //get products

    let type = this.$route.params.type;
    if (type === "amazing") {
      this.$store.dispatch("getOfferProducts").then(result => {
        this.products = result.items;
        console.log(result);
        this.loading = false;
      });
    } else {
      console.log(this.$route.path);
      this.$store.dispatch("getBoxProducts").then(result => {
        result.forEach(box => {
          if (box.link_show_all === this.$route.path) {
            this.products = box.products;
          }
        });
        console.log(result);
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
