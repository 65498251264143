<template>
  <div>
    <div
      v-if="$store.getters.showBasketStatus === true"
      @click="basketChangeStatus()"
      class="container-fluid shoping-cart"
    ></div>
    <div v-if="$store.getters.showBasketStatus === true" class="">
      <div class="shop-basket" style="margin-left: 0; margin-right: 0">
        <div class="d-flex justify-content-between align-item-center">
          <span>سبد خرید شما</span>
          <a @click="basketChangeStatus()" href="#" class="">
            <img
              width="20px"
              id="cross"
              src="/template/images/Cross.svg"
              alt=""
              class="icon"
            />
          </a>
        </div>
        <hr />
        <div
          v-for="(product, index) in products"
          :key="product[index]"
          class="product"
        >
          <img
            :src="product.image"
            alt="product"
            width="50px"
            @error="product.image = '/template/images/no-image.png'"
          />
          <div
            style="width: 250px"
            class="d-flex flex-column justify-content-center m-2 text-right"
          >
            <p>{{ product.name }}</p>
            <!-- <br /> -->
            <p class="new-price">تعداد : {{ product.quantity }}</p>

            <!-- special price -->
            <div v-if="product.special_price && product.special_price !== 0">
              <div class="d-flex align-items-center w-max">
                <p class="old-price">
                  {{ new Intl.NumberFormat().format(product.price) }}
                  ریال
                </p>
                <p class="discount-percent mr-2" style="width: 50px">
                  {{
                    new Intl.NumberFormat().format(
                      (
                        ((Number(product.price) -
                          Number(product.special_price)) /
                          Number(product.price)) *
                        100
                      ).toFixed(0)
                    ) + "%"
                  }}
                </p>
              </div>
              <p class="new-price">
                {{ new Intl.NumberFormat().format(product.special_price) }}
                ریال
              </p>
            </div>
            <!-- special price -->

            <!-- price -->
            <p v-else class="new-price">
              {{ new Intl.NumberFormat().format(product.price) }}
              ریال
            </p>
            <!-- price -->
          </div>
          <a @click="removeFromBasket(product)" class="remove">
            <i class="fa fa-close"></i>
          </a>
        </div>
        <div class="sum">
          <p id="sum-span">جمع کل</p>
          <p id="sum-price">
            {{ new Intl.NumberFormat().format(totalPrice) }} ریال
          </p>
        </div>
        <hr id="line-top-button" />
        <div class="button">
          <input
            @click="goToBasket()"
            type="button"
            value="مشاهده‌ی سبد خرید"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "side-basket",
  computed: {
    products() {
      return this.$store.getters.getBasket;
    },
    totalPrice() {
      let total = 0;

      this.$store.getters.getBasket.forEach(
        item =>
          (total +=
            item.special_price && item.special_price != 0
              ? Number(item.special_price) * Number(item.quantity)
              : Number(item.price) * Number(item.quantity))
      );

      return total;
    }
  },
  methods: {
    basketChangeStatus() {
      this.$store.dispatch("setBasketStatus", {
        status: !this.$store.getters.showBasketStatus
      });
    },
    goToBasket() {
      this.$store.dispatch("setBasketStatus", {
        status: !this.$store.getters.showBasketStatus
      });
      //refresh to cart
      // window.location.href = "/cart";
      this.$router.push("/cart");
    },
    removeFromBasket(product) {
      this.showProfileQuickAccess = false;
      this.$store
        .dispatch("removeFromBasket", {
          product: product
        })
        .then(() => {
          this.products = this.$store.getters.getBasket;
        });
      //is token exist remove from db
      if (localStorage.getItem("token")) {
        this.$store
          .dispatch("removeFromBasketReq", {
            product: product
          })
          .then(res => {
            if (!res) {
              this.$swal
                .fire({
                  position: "center",
                  icon: "warning",
                  title: "خطا در حذف محصول",
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  //failed then add to basket again
                  this.$store.dispatch("addToBasket", {
                    product: product
                  });
                });
            }
          });
      }
    }
  }
};
</script>
<style lang="css">
.shop-basket {
  position: absolute;
  width: 320px;
  top: 4rem;
  right: 3rem;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  width: 360px;
  z-index: 1000;
  background-color: #fff;
  padding: 15px 17px;
  position: absolute;
  -webkit-box-shadow: 0 10px 10px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 2px 2px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
}

@media (max-width: 992px) {
  .shop-basket {
    top: 4rem !important;
    left: auto !important;
    right: 10px;
  }
}
@media (max-width: 450px) {
  .shop-basket {
    width: 300px;
  }
}

.tiny-image {
  width: 90px;
  height: 100px;
}

.product {
  font-size: 14px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
}

.product p {
  margin: 0;
}
.remove {
  margin: auto auto auto 0;
  background-color: red;
  /* padding: 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.remove i {
  color: #fff !important;
  display: flex;
}
/* .product img {
  width: 70px;
} */
</style>
